import { SxProps, Theme, Typography, styled } from "@mui/material";
import { CSSProperties } from "react";

interface TextProps {
  text: string;
  color?: string;
  fontWeight?: CSSProperties["fontWeight"];
  sx?: SxProps<Theme> | undefined;
}

const Text = ({ text, color, fontWeight, sx }: TextProps) => {
  const InputLabelStyles = styled(Typography)(({ theme }) => ({
    color: color || theme.palette.app.secondaryFont,
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: fontWeight || "400",
    lineHeight: "22px",
  }));

  return <InputLabelStyles sx={sx}>{text}</InputLabelStyles>;
};

export default Text;
