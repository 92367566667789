import { SxProps, Theme, Typography, styled } from "@mui/material";

interface BoldSubtitleProps {
  text: string;
  color?: string;
  sx?: SxProps<Theme> | undefined;
}

const BoldSubtitle = ({ text, color, sx }: BoldSubtitleProps) => {
  const BoldSubtitleStyles = styled(Typography)(({ theme }) => ({
    color: color || theme.palette.app.secondaryFont,
    fontFamily: "isonType",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "28px",
  }));

  return (
    <BoldSubtitleStyles variant="h1" sx={sx}>
      {text}
    </BoldSubtitleStyles>
  );
};

export default BoldSubtitle;
