import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { ThemeName } from "../ThemeUsage/theme";

const Footer = () => {
  const [themeName] = useState<ThemeName>(
    (sessionStorage.getItem("theme") || "default") as ThemeName
  );

  if (themeName === "disco") {
    return <></>;
  }

  return (
    <Stack
      sx={{
        mt: "auto",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#FDA100",
          width: "100%",
          height: "16px",
        }}
      />
      <Box
        sx={{
          backgroundColor: "#F2251C",
          width: "100%",
          height: "16px",
        }}
      />
      <Box
        sx={{
          backgroundColor: "#6D379C",
          width: "100%",
          height: "16px",
        }}
      />
      <Box
        sx={{
          backgroundColor: "#004F43",
          width: "100%",
          height: "16px",
        }}
      />
    </Stack>
  );
};

export default Footer;
