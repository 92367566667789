import { REGEX } from "src/consts/regex";
import {
  PaymentDetailsFormValues,
  PaymentDetailsTypes,
} from "src/pages/PaymentDetails";
import * as yup from "yup";
import IBAN from "iban";

const validateGermanIBAN = (iban: string) => {
  return IBAN.isValid(`DE${iban}`);
};

export const paymentDetailsFormSchema: yup.ObjectSchema<PaymentDetailsFormValues> =
  yup.object({
    account: yup
      .mixed<PaymentDetailsTypes>()
      .notOneOf(
        ["" as PaymentDetailsTypes.BLANK],
        "page.paymentDetails.formErrors.choosePaymentMethod"
      ),
    cardHolder: yup
      .string()
      .when("account", (account, schema) =>
        account[0] === PaymentDetailsTypes.SEPA
          ? schema
              .required("page.paymentDetails.formErrors.accountHolder.required")
              .max(100, "page.paymentDetails.formErrors.accountHolder.maxLimit")
              .matches(
                REGEX.CARD_HOLDER,
                "page.paymentDetails.formErrors.accountHolder.invalid"
              )
          : schema.notRequired()
      ),
    consent: yup
      .boolean()
      .when("account", (account, schema) =>
        account[0] === PaymentDetailsTypes.SEPA
          ? schema
              .required("page.paymentDetails.formErrors.consent.required")
              .oneOf([true], "page.paymentDetails.formErrors.consent.required")
          : schema.notRequired()
      ),
    iban: yup
      .string()
      .when("account", (account, schema) =>
        account[0] === PaymentDetailsTypes.SEPA
          ? schema
              .required("page.paymentDetails.formErrors.iban.required")
              .test(
                "is-valid-iban",
                "page.paymentDetails.formErrors.iban.invalid",
                validateGermanIBAN
              )
              .matches(
                REGEX.DE_IBAN,
                "page.paymentDetails.formErrors.iban.invalid"
              )
          : schema.notRequired()
      ),
  });
