export enum SESSION_KEYS {
  SITE_CORRELATION_ID = "siteCorrelationId",
  UI_FLOW_PREFERENCES = "uiFlowPreferences",
  USER_PROFILE_DATA = "userProfileData",
  SITE_TECHNICAL_DATA = "siteTechnicalData",
  TARIFFS = "tariffs",
  TRANSFER_ID = "transferId",
  FUNNEL_ORDER_RESPONSE = "funnelOrderResponse",
}

export interface UI_FLOW_PREFERENCES {
  language?: string;
  callbackUrlSuccess?: string;
  callbackUrlFailure?: string;
}

export interface USER_PROFILE_DATA {
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  email?: string;
  confirmEmail?: string;
  street?: string;
  houseNumber?: string;
  zipCode?: string;
  city?: string;
  useDeliveryAddressChecked?: string;
  streetDeliveryAddress?: string;
  streetNumberDeliveryAddress?: string;
  zipCodeDeliveryAddress?: string;
  cityDeliveryAddress?: string;
  orderedTariff?: string;
  tarif?: string;
  country?: string;
  yearlyConsumptionKwh?: string;
  personsInHousehold?: number;
}

export interface SITE_TECHNICAL_DATA {
  cancelContractType?: string;
  contractStartDate?: string;
  paymentDetails?: {
    account?: string;
    cardHolder?: string;
    iban?: string;
    consent?: boolean;
  };
  batterySetup?: {
    batteryInstalled?: string;
    batteryCapacity?: string;
  };
  pvSetup?: {
    pvInstalled?: string;
    pvPowerMaxKwp?: string;
    postalCode?: string;
  };
  wallboxInstalled?: string;
  meterNumber?: string;
  marketLocationId?: string;
  energyProvider?: string;
  energyProviderUuid?: string;

  smartMeterInstalled?: string;
  heatpumpInstalled?: string;
}

export interface FUNNEL_ORDER_RESPONSE {
  msgId: string;
  redirectUrl: string;
}
