import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

const HorizontalLinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  borderRadius: 5,
  height: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.progressBar.background,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.progressBar.primary,
  },
}));

export default HorizontalLinearProgressBar;
