import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Stack, ThemeProvider, createTheme } from "@mui/material";

import { Calendar } from "src/assets";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { LocalizationProvider, PickersLocaleText } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { de } from "date-fns/locale";
import InputLabel from "../Displays/InputLabel";

const newTheme = (theme: any) =>
  createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        main: theme.palette.app.accent,
      },
    },
    typography: {
      ...theme.tytypography,
      allVariants: {
        color: "#111",
      },
    },
  });

interface Props<T extends FieldValues> {
  name: Path<T>;
  minDate?: Date;
  maxDate?: Date;
  label?: string;
  format?: string;
  control: Control<T>;
}

const customPtBRLocaleText: Partial<PickersLocaleText<any>> = {
  timeTableLabel: "Zeitplan",
  clearButtonLabel: "Löschen",
  okButtonLabel: "Ok",
  cancelButtonLabel: "Abbrechen",
};

const DatePickerInput = <T extends FieldValues>({
  name,
  minDate,
  maxDate,
  label = "",
  format = "d. MMMM yyyy",
  control,
}: Props<T>) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={de}
      localeText={customPtBRLocaleText}
    >
      <ThemeProvider theme={newTheme}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Stack gap={1}>
              <InputLabel text={label} />
              <DatePicker
                {...field}
                minDate={minDate}
                maxDate={maxDate}
                format={format}
                slots={{
                  openPickerIcon: Calendar,
                }}
                slotProps={{
                  actionBar: { actions: ["cancel", "accept"] },
                  field: {
                    clearable: true,
                    readOnly: true,
                  },
                  toolbar: {
                    hidden: true,
                  },
                }}
              />
            </Stack>
          )}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default DatePickerInput;
