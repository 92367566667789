import { Box, Link } from "@mui/material";
import CheckboxInput from "../Inputs/CheckboxInput";
import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";
import { ContractSummaryFormValues } from "src/pages/ContractSummary/ContractSummary";

interface ContractSummaryFormProps {
  form: UseFormReturn<ContractSummaryFormValues, any, undefined>;
}

const ContractSummaryForm = ({ form }: ContractSummaryFormProps) => {
  const { t } = useTranslation();
  return (
    <>
      <CheckboxInput
        control={form.control}
        checkboxTextComponent={
          <Box
            sx={(theme) => ({
              color: theme.palette.app.secondaryFont,
            })}
          >
            {t("page.contractSummary.smartMeterCheckboxText1")}
            <Link
              sx={(theme) => ({
                color: theme.palette.app.secondaryFont,
                textDecoration: "underline",
              })}
              href="https://www.lichtblick.de/veroeffentlichungen/"
              target="_blank"
            >
              {t("page.contractSummary.smartMeterCheckboxLink1")}
            </Link>
            {t("page.contractSummary.smartMeterCheckboxText2")}
            <Link
              sx={(theme) => ({
                color: theme.palette.app.secondaryFont,
                textDecoration: "underline",
              })}
              href="https://www.lichtblick.de/veroeffentlichungen/"
              target="_blank"
            >
              {t("page.contractSummary.smartMeterCheckboxLink2")}
            </Link>
            {t("page.contractSummary.and")}
            <Link
              sx={(theme) => ({
                color: theme.palette.app.secondaryFont,
                textDecoration: "underline",
              })}
              href="https://www.lichtblick.de/datenschutz/"
              target="_blank"
            >
              {t("page.contractSummary.walletLink2")}
            </Link>
            .
          </Box>
        }
        name="smartMeter"
      />
      <CheckboxInput
        control={form.control}
        checkboxTextComponent={
          <Box
            sx={(theme) => ({
              color: theme.palette.app.secondaryFont,
            })}
          >
            {t("page.contractSummary.smartMeterCheckboxText1")}
            <Link
              sx={(theme) => ({
                color: theme.palette.app.secondaryFont,
                textDecoration: "underline",
              })}
              href="https://www.lichtblick.de/veroeffentlichungen/"
              target="_blank"
            >
              {t("page.contractSummary.walletLink1")}
            </Link>
            {t("page.contractSummary.smartMeterCheckboxText2")}
            <Link
              sx={(theme) => ({
                color: theme.palette.app.secondaryFont,
                textDecoration: "underline",
              })}
              href="https://www.lichtblick.de/veroeffentlichungen/"
              target="_blank"
            >
              {t("page.contractSummary.smartMeterCheckboxLink2")}
            </Link>
            {t("page.contractSummary.and")}
            <Link
              sx={(theme) => ({
                color: theme.palette.app.secondaryFont,
                textDecoration: "underline",
              })}
              href="https://www.lichtblick.de/datenschutz/"
              target="_blank"
            >
              {t("page.contractSummary.walletLink2")}
            </Link>
            .
          </Box>
        }
        name="wallet"
      />
    </>
  );
};

export default ContractSummaryForm;
