import { useTheme } from "@mui/material";
import { LichtBlickLogo } from "src/assets";

const TariffProviderIcon = ({
  tariffProviderDisplayName,
}: {
  tariffProviderDisplayName: string;
}) => {
  const theme = useTheme();

  switch (tariffProviderDisplayName) {
    case "LichtBlick":
      return <LichtBlickLogo color={theme.palette.app.primaryFont} />;
    default:
      return <></>;
  }
};

export default TariffProviderIcon;
