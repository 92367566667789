import { Typography, TypographyProps, styled } from "@mui/material";

interface MobileTitleProps extends TypographyProps {
  text: string;
  color?: string;
}

const MobileTitle = ({ text, color, ...props }: MobileTitleProps) => {
  const InputLabelStyles = styled(Typography)(({ theme }) => ({
    color: color || theme.palette.app.secondaryFont,
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22px",
  }));

  return <InputLabelStyles {...props}>{text}</InputLabelStyles>;
};

export default MobileTitle;
