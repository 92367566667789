import { format, isValid, parseISO } from "date-fns";

export const dateFormat = (date: string) => {
  const isValidDate = isValid(parseISO(date));
  if (!isValidDate) return date;
  return format(new Date(date), "dd.MM.yyyy");
};

export const dateFormatForPayload = (date: string) => {
  const isValidDate = isValid(parseISO(date));
  if (!isValidDate) return date;
  return format(new Date(date), "yyyy-MM-dd");
};
