import FormContainer from "src/components/Displays/FormContainer";
import Page from "src/components/Layout/Page";
import { useTranslation } from "react-i18next";
import { contractChangeInputsFormSchema } from "src/schema/contractChangeInputsFormSchema";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ContractChangeInputsForm from "src/components/Forms/ContractChangeInputsForm";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { SESSION_KEYS, SITE_TECHNICAL_DATA } from "src/consts/session";
import { useEffect } from "react";
import { ROUTES } from "src/consts/routes";

export interface ContractChangeFormValues {
  cancelContractType: string;
}

export enum CancelContractTypes {
  AUTO = "auto",
  SELF = "self",
}

const ContractChange = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formTitle = t("page.contractChange.formTitle");
  const formSubTitle = t("page.contractChange.formSubTitle");
  const pageTitle = t("page.contractChange.pageTitle");
  const pageSubTitle = t("page.contractChange.pageSubTitle");

  const [getSessionStorageItem, setSessionStorageItem] =
    useSessionStorage<SITE_TECHNICAL_DATA>(SESSION_KEYS.SITE_TECHNICAL_DATA);

  const { reset, control, handleSubmit } = useForm<ContractChangeFormValues>({
    defaultValues: {
      cancelContractType: CancelContractTypes.AUTO,
    },
    mode: "onSubmit",
    resolver: yupResolver(contractChangeInputsFormSchema),
  });

  useEffect(() => {
    const localData = getSessionStorageItem();
    reset({
      cancelContractType: localData?.cancelContractType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const onSubmit = (data: ContractChangeFormValues) => {
    const sessionData = getSessionStorageItem();
    const updatedSessionData: SITE_TECHNICAL_DATA = {
      ...sessionData,
      cancelContractType: data.cancelContractType,
    };
    setSessionStorageItem(updatedSessionData);

    if (data.cancelContractType === CancelContractTypes.SELF) {
      navigate(ROUTES.PAYMENT_DETAILS);
      return;
    }

    navigate(ROUTES.CURRENT_SUPPLIER);
  };

  const handleBackClick = () => {
    navigate(ROUTES.TECHNICAL_DETAILS);
  };

  return (
    <Page pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <FormContainer
        title={formTitle}
        subTitle={formSubTitle}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ marginTop: 6, marginBottom: 3 }}>
          <ContractChangeInputsForm control={control} />
        </Box>
        <FormSubmitContainer onClick={handleBackClick} />
      </FormContainer>
    </Page>
  );
};

export default ContractChange;
