import { UseFormReturn } from "react-hook-form";
import RadioGroupInput from "src/components/Inputs/RadioGroupInput";
import RadioInput from "src/components/Inputs/RadioInput";
import { useTranslation } from "react-i18next";

import { WallboxFormValues, WallboxTypes } from "src/pages/Wallbox";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { GlobalContextProps } from "../GlobalContext/GlobalContext";

interface WallboxFormProps {
  form: UseFormReturn<WallboxFormValues, any, undefined>;
}

const WallboxForm = ({ form }: WallboxFormProps) => {
  const { t } = useTranslation();
  const { setAnimations } = useOutletContext<GlobalContextProps>();

  const watchedPvInstalled = form.watch("wallboxInstalled");

  useEffect(() => {
    watchedPvInstalled === WallboxTypes.JA
      ? setAnimations((prev) => ({ ...prev, Car: true }))
      : setAnimations((prev) => ({ ...prev, Car: false }));
  }, [watchedPvInstalled, form, setAnimations]);

  return (
    <RadioGroupInput
      sx={{
        gap: 3,
      }}
      control={form.control}
      name="wallboxInstalled"
    >
      <RadioInput
        value={WallboxTypes.NEIN}
        label={t("page.wallbox.no")}
        labelPlacement="start"
        sx={(theme) => ({
          borderRadius: 2,
          border: `1px solid ${theme.palette.grey[100]}`,
          p: 4,
          display: "flex",
          justifyContent: "space-between",
          m: 0,
        })}
      />
      <RadioInput
        value={WallboxTypes.JA}
        label={t("page.wallbox.yes")}
        labelPlacement="start"
        sx={(theme) => ({
          borderRadius: 2,
          border: `1px solid ${theme.palette.grey[100]}`,
          p: 4,
          display: "flex",
          justifyContent: "space-between",
          m: 0,
        })}
      />
    </RadioGroupInput>
  );
};

export default WallboxForm;
