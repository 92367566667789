import { Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Intro, MobileIntro } from "src/assets";
import BrandItemThemeChanger from "src/components/BrandIconThemeChanger/BrandIconThemeChanger";
import BoldTitle from "src/components/Displays/BoldTitle";
import MobileTitle from "src/components/Displays/MobileTitle";

const Header = () => {
  const theme = useTheme();

  const { t } = useTranslation();
  return (
    <>
      <Stack
        sx={(theme) => ({
          color: theme.palette.grey[500],
          width: "100%",
          py: 4,
          px: 4,
          maxWidth: "850px",
        })}
      >
        <BrandItemThemeChanger color={theme.palette.app.secondaryFont} />
      </Stack>

      <Stack
        sx={{
          alignItems: "center",
          width: "fit-content",
          height: "fit-content",
          maxHeight: "fit-content",
          position: "relative",
          borderRadius: "15px",
          overflow: "hidden",
        }}
      >
        <Stack
          sx={{
            display: { xs: "none", sm: "flex" },
          }}
        >
          <Intro
            style={{
              width: "100%",
              height: "100%",
            }}
          />
          <Stack
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "250px",
              background:
                "linear-gradient(to top, rgba(0, 0, 0, 0.888), transparent)",
            }}
          />
        </Stack>
        <Stack
          sx={{
            display: { xs: "flex", sm: "none" },
          }}
        >
          <img
            src={MobileIntro}
            alt="mobile-intro"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
          <Stack
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "250px",
              background:
                "linear-gradient(to top, rgba(0, 0, 0, 0.888), transparent)",
            }}
          />
        </Stack>
        <Stack
          gap={6}
          sx={{
            maxWidth: "485px",
            position: "absolute",
            left: { lg: "201px", md: "100px", sm: "0px" },
            bottom: "29px",
            px: { xs: 4, lg: 0 },
          }}
        >
          <BoldTitle
            color={theme.palette.app.contrast}
            sx={{
              fontSize: { xs: "36px", sm: "48px" },
              lineHeight: { xs: "39px", sm: "50px" },
            }}
            text={t("page.introduction.title")}
          />
          <MobileTitle
            text={t("page.introduction.subtitle")}
            color={theme.palette.app.contrast}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default Header;
