import { REGEX } from "src/consts/regex";
import { numberFormat } from "src/helpers/numberFormat";
import {
  BatterySetupFormValues,
  BatterySetupTypes,
} from "src/pages/BatterySetup";
import * as yup from "yup";

export const batterySetupFormSchema: yup.ObjectSchema<BatterySetupFormValues> =
  yup.object({
    batteryInstalled: yup
      .mixed<BatterySetupTypes>()
      .notOneOf(
        ["" as BatterySetupTypes.BLANK],
        "page.batterySetup.formError.required"
      ),

    batteryCapacity: yup.string().when("batteryInstalled", {
      is: (val: BatterySetupTypes) => val === BatterySetupTypes.JA,
      then: (schema) =>
        schema
          .transform((value) => {
            const updatedValue = numberFormat(value);

            const valueNum = +updatedValue;
            if (isNaN(valueNum)) return undefined;

            if (valueNum < 0.5 || valueNum > 15) return undefined;
            return value;
          })
          .matches(
            REGEX.ONLY_NUMBERS_WITH_COMMA,
            "page.batterySetup.formError.batteryCapacity"
          )
          .required("page.batterySetup.formError.batteryCapacity"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
