import { Link, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import BrandItemThemeChanger from "src/components/BrandIconThemeChanger/BrandIconThemeChanger";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import Text from "src/components/Displays/Text";

const Footer = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        py: "32px",
      }}
      color={theme.palette.app.primary}
      alignItems="center"
    >
      <Stack
        sx={{
          width: "100%",
          px: 4,
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "center", sm: "space-between" },
          alignItems: { xs: "flex-start", sm: "center" },
          maxWidth: "880px",
        }}
        gap={6}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Text
            text={t("footer.poweredBy")}
            fontWeight={700}
            color={theme.palette.app.contrast}
          />
          <BrandItemThemeChanger color={theme.palette.app.contrast} />
        </Stack>

        <Stack direction="row" flexWrap={"wrap"} gap={3} mb={2}>
          <Link
            sx={(theme) => ({
              textDecoration: "none",
            })}
            href="https://ison.energy/en/legal-notice/"
            target="_blank"
          >
            <PageSubTitle
              color={theme.palette.app.contrast}
              text={t("footer.legalNotice")}
            />
          </Link>
          <Link
            sx={(theme) => ({
              textDecoration: "none",
            })}
            href="https://ison.energy/en/privacy/"
            target="_blank"
          >
            <PageSubTitle
              color={theme.palette.app.contrast}
              text={t("footer.privacy")}
            />
          </Link>
          <Link
            sx={(theme) => ({
              textDecoration: "none",
              fontSize: "14px",
            })}
            href="https://ison.energy/#contact"
            target="_blank"
          >
            <PageSubTitle
              color={theme.palette.app.contrast}
              text={t("footer.contact")}
            />
          </Link>
          <Link
            sx={(theme) => ({
              textDecoration: "none",
            })}
            href="https://www.lichtblick.de/vertrag-kuendigen/"
            target="_blank"
          >
            <PageSubTitle
              color={theme.palette.app.contrast}
              text={t("footer.terminateContract")}
            />
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
