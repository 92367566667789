import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { Box, Stack, TextField, TextFieldProps, styled } from "@mui/material";
import InputLabel from "src/components/Displays/InputLabel";
import InputHelperText from "src/components/Displays/InputHelperText";
import { useTranslation } from "react-i18next";

interface TextInputProps<T extends FieldValues> extends UseControllerProps<T> {
  labelText: string;
  labelEndAdornment?: React.ReactNode;
}

const TextInput = <T extends FieldValues>({
  name,
  control,
  labelText,
  helperText,
  labelEndAdornment,
  ...props
}: TextInputProps<T> & TextFieldProps) => {
  const { t } = useTranslation();

  const TextFieldStyles = styled(TextField)(({ theme }) => ({
    input: {
      color: theme.palette.app.secondaryFont,
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "24px",
      padding: "12px 16px 12px 16px",
    },
  }));

  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <Box>
          <Box height={4} />
          <Stack gap={1} direction="row" alignItems="center">
            <InputLabel text={labelText} />
            {labelEndAdornment}
          </Stack>
          <Box height={4} />
          <TextFieldStyles
            {...props}
            {...field}
            value={field.value ?? ""}
            id={field.name}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": {
                  border: error ? "1px solid #BD002A" : "1px solid #D5D5D5",
                },
                "&:hover fieldset": {
                  border: error ? "1px solid #BD002A" : "1px solid #D5D5D5",
                },
                "&.Mui-focused fieldset": {
                  border: error ? "1px solid #BD002A" : "1px solid #000000",
                },
              },
            }}
          />
          <Box height={4} />
          {error?.message && (
            <>
              <Box height={4} />
              <InputHelperText text={t(`${error.message}` as any)} />
            </>
          )}
        </Box>
      )}
      name={name}
      control={control}
    />
  );
};

export default TextInput;
