import { Box, Stack } from "@mui/material";
import { ReactNode } from "react";
import PageTitle from "src/components/Displays/PageTitle";
import PageSubTitle from "src/components/Displays/PageSubTitle";

interface PageProps {
  children: ReactNode;
  pageTitle: string;
  pageSubTitle?: string;
}

const Page = ({ children, pageTitle, pageSubTitle }: PageProps) => {
  return (
    <>
      <Stack gap={2} display={{ xs: "none", sm: "block" }}>
        <PageTitle text={pageTitle} />
        {pageSubTitle && (
          <>
            <Box height={8} />
            <PageSubTitle text={pageSubTitle} />
          </>
        )}
        <Box height={24} />
      </Stack>

      <Box
        sx={{
          width: { xs: "100%", lg: "605px" },
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default Page;
