import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FormContainer from "src/components/Displays/FormContainer";
import FormHeader from "src/components/Displays/FormHeader";
import PaymentDetailsForm from "src/components/Forms/PaymentDetailsForm";

import Page from "src/components/Layout/Page";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { ROUTES } from "src/consts/routes";
import { SITE_TECHNICAL_DATA, SESSION_KEYS } from "src/consts/session";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { paymentDetailsFormSchema } from "src/schema/paymentDetailsFormSchema";
import { CancelContractTypes } from "src/pages/ContractChange";

export enum PaymentDetailsTypes {
  SEPA = "SEPA",
  BANK = "BANK",
  BLANK = "",
}

export interface PaymentDetailsFormValues {
  account?: PaymentDetailsTypes;
  cardHolder?: string;
  iban?: string;
  consent?: boolean;
}

const PaymentDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageTitle = t("page.paymentDetails.pageTitle");
  const pageSubTitle = t("page.paymentDetails.pageSubtitle");
  const formTitle = t("page.paymentDetails.formTitle");
  const formSubtitle = t("page.paymentDetails.formSubTitle");

  const [getSessionStorageItem, setSessionStorageItem] =
    useSessionStorage<SITE_TECHNICAL_DATA>(SESSION_KEYS.SITE_TECHNICAL_DATA);

  const form = useForm<PaymentDetailsFormValues>({
    defaultValues: {
      account:
        (getSessionStorageItem()?.paymentDetails
          ?.account as PaymentDetailsTypes) || PaymentDetailsTypes.BLANK,
      cardHolder: getSessionStorageItem()?.paymentDetails?.cardHolder || "",
      iban: getSessionStorageItem()?.paymentDetails?.iban || "",
      consent: getSessionStorageItem()?.paymentDetails?.consent || false,
    },
    mode: "onSubmit",
    resolver: yupResolver(paymentDetailsFormSchema),
  });

  const handleBackClick = () => {
    const cancelContractType = getSessionStorageItem()?.cancelContractType;
    if (cancelContractType && cancelContractType === CancelContractTypes.SELF) {
      navigate(ROUTES.CONTRACT_CHANGE);
      return;
    }
    navigate(ROUTES.DATE_OF_CONTRACT_START);
  };

  const onSubmit = (data: PaymentDetailsFormValues) => {
    const sessionData = getSessionStorageItem();
    const updatedSessionData: SITE_TECHNICAL_DATA = {
      ...sessionData,
      paymentDetails: {
        account: data.account,
        cardHolder: data.cardHolder?.trim(),
        iban: `${data.iban?.replaceAll(" ", "")}`,
        consent: data.consent,
      },
    };
    if (data.account === PaymentDetailsTypes.BANK) {
      delete updatedSessionData.paymentDetails?.cardHolder;
      delete updatedSessionData.paymentDetails?.iban;
      delete updatedSessionData.paymentDetails?.consent;
    }
    setSessionStorageItem(updatedSessionData);
    navigate(ROUTES.CONTRACT_SUMMARY);
  };

  return (
    <Page pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <FormContainer title={formTitle} onSubmit={form.handleSubmit(onSubmit)}>
        <Box
          sx={{
            my: 6,
          }}
        >
          <FormHeader text={formSubtitle} />
        </Box>
        <Box sx={{ marginTop: 6, marginBottom: 3 }}>
          <PaymentDetailsForm form={form} />
        </Box>
        <FormSubmitContainer onClick={handleBackClick} />
      </FormContainer>
    </Page>
  );
};

export default PaymentDetails;
