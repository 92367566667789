import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import {
  Box,
  InputAdornment,
  TextField,
  TextFieldProps,
  styled,
} from "@mui/material";
import InputLabel from "src/components/Displays/InputLabel";
import InputHelperText from "src/components/Displays/InputHelperText";
import { useTranslation } from "react-i18next";

interface MobileNumberInputProps<T extends FieldValues>
  extends UseControllerProps<T> {
  labelText: string;
}

const MobileNumberInput = <T extends FieldValues>({
  name,
  control,
  labelText,
  helperText,
  ...props
}: MobileNumberInputProps<T> & TextFieldProps) => {
  const { t } = useTranslation();

  const TextFieldStyles = styled(TextField)(({ theme }) => ({
    input: {
      color: theme.palette.app.secondaryFont,
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "24px",
      padding: "12px 16px 12px 0px",
    },
  }));

  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <Box>
          <Box height={4} />
          <InputLabel text={labelText} />
          <Box height={4} />
          <TextFieldStyles
            {...props}
            {...field}
            id="textField"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    sx={(theme) => ({
                      fontSize: "18px",
                      color: theme.palette.app.secondaryFont,
                    })}
                  >
                    +49
                  </Box>
                </InputAdornment>
              ),
            }}
            onBlur={(event) => {
              field.onBlur();

              const value = event.target.value;
              if (!value) return;

              const mobileNumberFormatted = Number(value).toString();
              if (isNaN(+mobileNumberFormatted)) {
                return;
              }

              field.onChange(mobileNumberFormatted);
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": {
                  border: error ? "1px solid #BD002A" : "1px solid #D5D5D5",
                },
                "&:hover fieldset": {
                  border: error ? "1px solid #BD002A" : "1px solid #D5D5D5",
                },
                "&.Mui-focused fieldset": {
                  border: error ? "1px solid #BD002A" : "1px solid #000000",
                },
              },
            }}
          />
          <Box height={4} />
          {error?.message && (
            <>
              <Box height={4} />
              <InputHelperText text={t(`${error.message}` as any)} />
            </>
          )}
        </Box>
      )}
      name={name}
      control={control}
    />
  );
};

export default MobileNumberInput;
