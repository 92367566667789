import PageTitle from "src/components/Displays/PageTitle";
import { useTranslation } from "react-i18next";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import { Box, Stack } from "@mui/material";
import PrimaryButton from "src/components/Shared/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { FUNNEL_ORDER_RESPONSE, SESSION_KEYS } from "src/consts/session";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { MSG_IDS } from "src/consts/msgIds";

const ErrorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [getFunnelOrderResponse] = useSessionStorage<FUNNEL_ORDER_RESPONSE>(
    SESSION_KEYS.FUNNEL_ORDER_RESPONSE
  );

  const subTitle = t(
    `page.errorPage.${getFunnelOrderResponse()?.msgId as MSG_IDS}`
  );

  const handleBackClick = () => {
    let redirectUrl = getFunnelOrderResponse()?.redirectUrl;

    if (redirectUrl && redirectUrl !== "") {
      window.location.href = redirectUrl;
      return;
    }
    navigate(-1);
  };

  return (
    <>
      <Stack gap={2} sx={{ mt: { xs: 8, sm: 0 } }}>
        <PageTitle text={t("page.errorPage.title")} />
        <>
          <Box height={8} />
          <PageSubTitle text={subTitle} />
        </>
        <Box height={24} />
        <PrimaryButton
          text={t("page.errorPage.button")}
          sx={{
            border: "1px solid white",
            maxWidth: {
              xs: "100%",
              sm: "104px",
            },
          }}
          onClick={handleBackClick}
        />
      </Stack>
    </>
  );
};

export default ErrorPage;
