import { Typography, styled } from "@mui/material";

interface FormSubTitleProps {
  text?: string;
}

const FormSubTitle = ({ text }: FormSubTitleProps) => {
  const FormSubTitleStyles = styled(Typography)(({ theme }) => ({
    color: theme.palette.app.secondaryFont,
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "22px",
  }));

  return <FormSubTitleStyles mt={2}>{text}</FormSubTitleStyles>;
};

export default FormSubTitle;
