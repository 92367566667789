import { addYears } from "date-fns";
import DatePickerInput from "../Inputs/DatePickerInput";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { ContractStartDateFormValues } from "src/pages/ContractStartDate";
import InputWrapper from "src/components/Inputs/InputWrapper";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { Tariff } from "src/models/clientsModel";
import { SESSION_KEYS } from "src/consts/session";

interface Props {
  control: Control<ContractStartDateFormValues>;
}
const ContractStartDateForm = ({ control }: Props) => {
  const { t } = useTranslation();
  const [getTariffs] = useSessionStorage<Tariff[]>(SESSION_KEYS.TARIFFS);
  const tariffDisplayName = getTariffs()?.[0]?.tariffDisplayName || "";
  const dateInputLabel = t("page.contractChange.contractSignDate", {
    tariffDisplayName: tariffDisplayName,
  });
  const maxDate = addYears(new Date(), 2);
  const minDate = new Date();

  return (
    <InputWrapper>
      <DatePickerInput
        label={dateInputLabel}
        control={control}
        name="contractStartDate"
        minDate={minDate}
        maxDate={maxDate}
      />
    </InputWrapper>
  );
};

export default ContractStartDateForm;
