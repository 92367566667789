import * as yup from "yup";
import { YourNameFormValues } from "src/pages/YourName";
import { REGEX } from "src/consts/regex";

export const yourNameInputsFormSchema: yup.ObjectSchema<YourNameFormValues> =
  yup.object({
    firstName: yup
      .string()
      .required("page.yourName.formErrors.firstName")
      .min(1, "page.yourName.formErrors.firstName")
      .max(50, "page.yourName.formErrors.firstName")
      .test(
        "unsupported numbers",
        "page.yourName.formErrors.firstName",
        (firstName) => !REGEX.CONTAINS_NUMBERS.test(firstName)
      ),

    lastName: yup
      .string()
      .required("page.yourName.formErrors.lastName")
      .min(1, "page.yourName.formErrors.lastName")
      .max(50, "page.yourName.formErrors.lastName")
      .test(
        "unsupported numbers",
        "page.yourName.formErrors.lastName",
        (lastName) => !REGEX.CONTAINS_NUMBERS.test(lastName)
      ),
  });
