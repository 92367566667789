import { Typography, TypographyProps, styled } from "@mui/material";

interface BoldTitleProps extends TypographyProps {
  text: string;
  color?: string;
  opacity?: number;
}

const BoldTitle = ({ text, color, opacity, ...props }: BoldTitleProps) => {
  const BoldTitleStyles = styled(Typography)(({ theme }) => ({
    color: color || theme.palette.app.primaryFont,
    fontFamily: "isonType",
    opacity: opacity || 1,
    fontSize: "48px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "50px",
  }));

  return (
    <BoldTitleStyles variant="h1" {...props}>
      {text}
    </BoldTitleStyles>
  );
};

export default BoldTitle;
