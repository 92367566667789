import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import TextInput from "../Inputs/TextInput";
import LabelTitle from "../Displays/LabelTitle";
import { Energy, MinusCircle, Persons, PlusCircle } from "src/assets";
import { AnnualConsumptionFormValues } from "src/pages/AnnualConsumption";
import Text from "../Displays/Text";

interface AnnualConsumptionFormProps {
  form: UseFormReturn<AnnualConsumptionFormValues, any, undefined>;
}

const calculatePowerConsumption = (
  persons: number,
  form: UseFormReturn<AnnualConsumptionFormValues, any, undefined>
) => {
  switch (persons) {
    case 1:
      form.setValue("yearlyConsumptionKwh", "1500");
      break;
    case 2:
      form.setValue("yearlyConsumptionKwh", "2500");
      break;
    case 3:
      form.setValue("yearlyConsumptionKwh", "3500");
      break;
    case 4:
      form.setValue("yearlyConsumptionKwh", "4250");
      break;
    case 5:
      form.setValue("yearlyConsumptionKwh", "5200");
      break;
    default:
      return "";
  }
  form.trigger("yearlyConsumptionKwh");
};

const handleIncrement = (
  form: UseFormReturn<AnnualConsumptionFormValues, any, undefined>
) => {
  const personsInHousehold = form.getValues("personsInHousehold");

  if (personsInHousehold === 5) return;
  const updatedPersonsInHousehold = personsInHousehold + 1;
  form.setValue("personsInHousehold", updatedPersonsInHousehold);
  calculatePowerConsumption(updatedPersonsInHousehold, form);
};

const handleDecrement = (
  form: UseFormReturn<AnnualConsumptionFormValues, any, undefined>
) => {
  const personsInHousehold = form.getValues("personsInHousehold");

  if (personsInHousehold === 1) return;
  const updatedPersonsInHousehold = personsInHousehold - 1;

  form.setValue("personsInHousehold", updatedPersonsInHousehold);
  calculatePowerConsumption(updatedPersonsInHousehold, form);
};

const AnnualConsumptionForm = ({ form }: AnnualConsumptionFormProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      gap={2}
      sx={{
        px: 4,
        pb: 4,
      }}
    >
      <TextInput
        fullWidth
        InputProps={{
          readOnly: true,
          startAdornment: <Persons />,
          endAdornment: (
            <Stack direction="row" gap={2} alignItems="center">
              <Stack
                sx={{
                  position: "absolute",
                  left: "65px",
                }}
              >
                <Text text={t("page.annualConsumption.persons")} />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => handleDecrement(form)}
              >
                <MinusCircle />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => handleIncrement(form)}
              >
                <PlusCircle />
              </Stack>
            </Stack>
          ),
        }}
        control={form.control}
        name="personsInHousehold"
        labelText={t("page.annualConsumption.personsInHouseholdLable")}
      />
      <TextInput
        fullWidth
        placeholder={t("page.annualConsumption.annualConsumptionLable")}
        InputProps={{
          startAdornment: <Energy />,
          endAdornment: (
            <LabelTitle
              sx={{
                whiteSpace: "nowrap",
              }}
              fontWeight={600}
              text={t("page.annualConsumption.annualConsumptionUnitOfMeasure")}
            />
          ),
        }}
        control={form.control}
        name="yearlyConsumptionKwh"
        labelText={t("page.annualConsumption.annualConsumptionLable")}
      />
    </Stack>
  );
};

export default AnnualConsumptionForm;
