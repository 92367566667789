import { numberFormat } from "src/helpers/numberFormat";
import { AnnualConsumptionFormValues } from "src/pages/AnnualConsumption";
import * as yup from "yup";

export const annualConsumptionFormSchema: yup.ObjectSchema<AnnualConsumptionFormValues> =
  yup.object({
    personsInHousehold: yup.number().min(1).max(5).required(),

    yearlyConsumptionKwh: yup
      .string()
      .transform((value) => {
        const updatedValue = numberFormat(value);

        if (updatedValue.slice(-1) === "." || updatedValue.slice(-1) === ",")
          return undefined;

        const updatedValueWithoutComma = updatedValue.replace(",", "");
        const updatedValueNum = +updatedValueWithoutComma;

        if (isNaN(updatedValueNum)) return undefined;

        if (updatedValueNum < 1000 || updatedValueNum > 100000)
          return undefined;
        return value;
      })
      .required("page.annualConsumption.formError.invalid"),
  });
