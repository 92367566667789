import { Stack } from "@mui/material";
import { Control } from "react-hook-form";
import { TechnicalDetailsFormValues } from "src/pages/TechnicalDetails";
import TextInput from "../../Inputs/TextInput";
import { useTranslation } from "react-i18next";
import InputWrapper from "src/components/Inputs/InputWrapper";
import { QuestionMark } from "src/assets";
import { useToggle } from "src/hooks/useToggle";
import Modal from "../../Modal/Modal";
import PageTitle from "../../Displays/PageTitle";
import MeterNumberModalContent from "./MeterNumberModalContent";
import MarketLocationModalContent from "./MarketLocationModalContent";

interface TechnicalDetailsInputsFormProps {
  control: Control<TechnicalDetailsFormValues>;
}

const TechnicalDetailsInputsForm = ({
  control,
}: TechnicalDetailsInputsFormProps) => {
  const { t } = useTranslation();
  const meterNumberLabel = t("page.technicalDetails.formFileds.meterNumber");
  const marketLocationIdLabel = t(
    "page.technicalDetails.formFileds.marketLocationId"
  );
  const [isMarketModalOpen, toggleIsMarketModalOpen] = useToggle(false);
  const [isMeterModalOpen, toggleIsMeterModalOpen] = useToggle(false);

  return (
    <Stack sx={{ gap: 5 }}>
      <InputWrapper>
        <TextInput
          fullWidth
          control={control}
          name="meterNumber"
          labelText={meterNumberLabel}
          labelEndAdornment={
            <Stack
              sx={{
                cursor: "pointer",
              }}
            >
              <QuestionMark onClick={toggleIsMeterModalOpen} />
            </Stack>
          }
        />
      </InputWrapper>

      <InputWrapper>
        <TextInput
          fullWidth
          control={control}
          name="marketLocationId"
          labelText={marketLocationIdLabel}
          labelEndAdornment={
            <Stack
              sx={{
                cursor: "pointer",
              }}
            >
              <QuestionMark onClick={toggleIsMarketModalOpen} />
            </Stack>
          }
        />
      </InputWrapper>
      <Modal
        title={
          <PageTitle
            color="#222"
            text={t("page.technicalDetails.meterNumber.infoTitle")}
          />
        }
        isOpen={isMeterModalOpen}
        onClose={toggleIsMeterModalOpen}
        onSubmit={toggleIsMeterModalOpen}
      >
        <MeterNumberModalContent />
      </Modal>
      <Modal
        title={
          <PageTitle
            color="#222"
            text={t("page.technicalDetails.marketLocationId.infoTitle")}
          />
        }
        isOpen={isMarketModalOpen}
        onClose={toggleIsMarketModalOpen}
        onSubmit={toggleIsMarketModalOpen}
      >
        <MarketLocationModalContent />
      </Modal>
    </Stack>
  );
};

export default TechnicalDetailsInputsForm;
