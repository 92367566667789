import { Typography, styled } from "@mui/material";

interface PageSubTitleProps {
  text?: string;
  color?: string;
}

const PageSubTitle = ({ text, color }: PageSubTitleProps) => {
  const PageSubTitleStyles = styled(Typography)(({ theme }) => ({
    color: color || theme.palette.app.primaryFont,
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "18px",
  }));

  return <PageSubTitleStyles>{text}</PageSubTitleStyles>;
};

export default PageSubTitle;
