import { Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SmartMeter } from "src/assets";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import ProgressBarTitle from "src/components/Displays/ProgressBarTitle";

const SmartMeterModalContent = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Stack gap={4}>
      <Stack gap={4}>
        <SmartMeter />
        <PageSubTitle
          color={theme.palette.grey[400]}
          text={t("page.smartMeter.modal.imageDescription")}
        />
      </Stack>
      <ProgressBarTitle
        color={theme.palette.grey[400]}
        text={t("page.smartMeter.modal.firstText")}
      />
      <ProgressBarTitle
        color={theme.palette.grey[400]}
        text={t("page.smartMeter.modal.secondText")}
      />
      <ProgressBarTitle
        color={theme.palette.grey[400]}
        text={t("page.smartMeter.modal.thirdText")}
      />
    </Stack>
  );
};

export default SmartMeterModalContent;
