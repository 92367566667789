import { Typography, styled } from "@mui/material";

interface FormTitleProps {
  text: string;
  color?: string;
}

const FormTitle = ({ text, color }: FormTitleProps) => {
  const FormTitleStyles = styled(Typography)(({ theme }) => ({
    color: color || theme.palette.app.secondaryFont,
    fontFamily: "isonType",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "26px",
  }));

  return (
    <FormTitleStyles variant="h2" mt={2}>
      {text}
    </FormTitleStyles>
  );
};

export default FormTitle;
