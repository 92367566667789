import { Control } from "react-hook-form";
import RadioGroupInput from "src/components/Inputs/RadioGroupInput";
import { ContractChangeFormValues } from "src/pages/ContractChange";
import RadioInput from "src/components/Inputs/RadioInput";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { CancelContractTypes } from "src/pages/ContractChange";
interface ContractChangeInputsFormProps {
  control: Control<ContractChangeFormValues>;
}

const ContractChangeInputsForm = ({
  control,
}: ContractChangeInputsFormProps) => {
  const { t } = useTranslation();

  return (
    <RadioGroupInput control={control} name="cancelContractType">
      <RadioInput
        value={CancelContractTypes.AUTO}
        label={t("page.contractChange.formFields.contractAuto")}
      />

      <Box height={12} />

      <RadioInput
        value={CancelContractTypes.SELF}
        label={t("page.contractChange.formFields.contractSelf")}
      />
    </RadioGroupInput>
  );
};

export default ContractChangeInputsForm;
