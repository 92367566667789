import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Info } from "src/assets";
import Text from "src/components/Displays/Text";

const ModalContent = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={3}>
      <Stack direction="row" gap={1}>
        <Stack>
          <Info />
        </Stack>
        <Text text={t("page.calculatedSavings.modal.text1")} />
      </Stack>
      <Stack direction="row" gap={1}>
        <Stack>
          <Info />
        </Stack>
        <Text text={t("page.calculatedSavings.modal.text2")} />
      </Stack>
      <Stack direction="row" gap={1}>
        <Stack>
          <Info />
        </Stack>
        <Text text={t("page.calculatedSavings.modal.text3")} />
      </Stack>
      <Stack direction="row" gap={1}>
        <Stack>
          <Info />
        </Stack>
        <Text text={t("page.calculatedSavings.modal.text4")} />
      </Stack>
      <Stack direction="row" gap={1}>
        <Stack>
          <Info />
        </Stack>
        <Text text={t("page.calculatedSavings.modal.text5")} />
      </Stack>
      <Stack direction="row" gap={1}>
        <Stack>
          <Info />
        </Stack>
        <Text text={t("page.calculatedSavings.modal.text6")} />
      </Stack>
      <Stack direction="row" gap={1}>
        <Stack>
          <Info />
        </Stack>
        <Text text={t("page.calculatedSavings.modal.text7")} />
      </Stack>
    </Stack>
  );
};

export default ModalContent;
