import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import FormContainer from "src/components/Displays/FormContainer";
import WallboxForm from "src/components/Forms/WallboxForm";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { ROUTES } from "src/consts/routes";
import { SESSION_KEYS, SITE_TECHNICAL_DATA } from "src/consts/session";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { wallboxFormSchema } from "src/schema/wallboxFormSchema";
import ConfirmationModal from "src/components/Modal/ConfirmationModal";
import { useToggle } from "src/hooks/useToggle";
import { GlobalContextProps } from "src/components/GlobalContext/GlobalContext";
import { PvSetupTypes } from "./PvSetup";

export enum WallboxTypes {
  JA = "true",
  NEIN = "false",
  BLANK = "",
}

export interface WallboxFormValues {
  wallboxInstalled?: WallboxTypes;
}

const Wallbox = () => {
  const [isModalOpen, toggleModal] = useToggle(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const formTitle = t("page.wallbox.formTitle");
  const { setAnimations } = useOutletContext<GlobalContextProps>();

  const [getSessionStorageItem, setSessionStorageItem] =
    useSessionStorage<SITE_TECHNICAL_DATA>(SESSION_KEYS.SITE_TECHNICAL_DATA);

  const form = useForm<WallboxFormValues>({
    defaultValues: {
      wallboxInstalled:
        (getSessionStorageItem()?.wallboxInstalled as WallboxTypes) ||
        WallboxTypes.BLANK,
    },
    mode: "onSubmit",
    resolver: yupResolver(wallboxFormSchema),
  });

  const handleBackClick = () => {
    navigate(ROUTES.PV_SETUP);
  };

  const onSubmit = (data: WallboxFormValues) => {
    const sessionData = getSessionStorageItem();
    if (
      data.wallboxInstalled === WallboxTypes.NEIN &&
      getSessionStorageItem()?.pvSetup?.pvInstalled === PvSetupTypes.NEIN
    ) {
      toggleModal();
      return;
    }
    const updatedSessionData: SITE_TECHNICAL_DATA = {
      ...sessionData,
      wallboxInstalled: data.wallboxInstalled,
    };

    setSessionStorageItem(updatedSessionData);
    setAnimations((prev) => ({ ...prev, continue: !prev.continue }));
    navigate(ROUTES.BATTERY_SETUP);
  };

  const handleModalSubmit = () => {
    const sessionData = getSessionStorageItem();
    const updatedSessionData: SITE_TECHNICAL_DATA = {
      ...sessionData,
      wallboxInstalled: WallboxTypes.NEIN,
    };
    setSessionStorageItem(updatedSessionData);
    navigate(ROUTES.PV_SETUP);
  };

  return (
    <>
      <FormContainer title={formTitle} onSubmit={form.handleSubmit(onSubmit)}>
        <Box sx={{ marginTop: 6, marginBottom: 3 }}>
          <WallboxForm form={form} />
        </Box>
        <FormSubmitContainer onClick={handleBackClick} />
      </FormContainer>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onSubmit={handleModalSubmit}
        title={t("page.wallbox.modal.header")}
        subtitle={t("page.wallbox.modal.text")}
        closeButtonText={t("page.wallbox.modal.cancel")}
        submitButtonText={t("page.wallbox.modal.confirm")}
      />
    </>
  );
};

export default Wallbox;
