import { REGEX } from "src/consts/regex";
import { PvSetupFormValues, PvSetupTypes } from "src/pages/PvSetup";
import * as yup from "yup";
import { numberFormat } from "src/helpers/numberFormat";

export const pvSetupFormSchema: yup.ObjectSchema<PvSetupFormValues> =
  yup.object({
    pvInstalled: yup
      .mixed<PvSetupTypes>()
      .notOneOf(["" as PvSetupTypes.BLANK], "page.pvSetup.formError.required"),

    pvPowerMaxKwp: yup.string().when("pvInstalled", {
      is: (val: PvSetupTypes) => val === PvSetupTypes.JA,
      then: (schema) =>
        schema
          .transform((value) => {
            const updatedValue = numberFormat(value);

            const valueNum = +updatedValue;
            if (isNaN(valueNum)) return undefined;

            if (valueNum < 0.5 || valueNum > 20) return undefined;
            return value;
          })
          .matches(
            REGEX.ONLY_NUMBERS_WITH_COMMA,
            "page.pvSetup.formError.pvPowerMaxKwp"
          )
          .required("page.pvSetup.formError.pvPowerMaxKwp"),
      otherwise: (schema) => schema.notRequired(),
    }),

    postalCode: yup.string().when("pvInstalled", {
      is: (val: string) => val === PvSetupTypes.JA,
      then: (schema: yup.StringSchema) =>
        schema
          .required("page.pvSetup.formError.zipCode")
          .min(5, "page.pvSetup.formError.zipCode")
          .max(5, "page.pvSetup.formError.zipCode")
          .matches(REGEX.ZIP_CODE, "page.pvSetup.formError.zipCode"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
