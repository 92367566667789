import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  SvgIcon,
  useTheme,
} from "@mui/material";
import Text from "src/components/Displays/Text";

interface RadioInputProps
  extends Pick<FormControlLabelProps, "labelPlacement" | "sx"> {
  value: string;
  label: string;
}

const RadioInput = ({ value, label, sx, labelPlacement }: RadioInputProps) => {
  const theme = useTheme();
  return (
    <FormControlLabel
      labelPlacement={labelPlacement}
      sx={sx}
      value={value}
      control={
        <Radio
          icon={
            <SvgIcon sx={{ width: "16px", height: "16px" }}>
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 8.38379C1 4.5178 4.13401 1.38379 8 1.38379C11.866 1.38379 15 4.5178 15 8.38379C15 12.2498 11.866 15.3838 8 15.3838C4.13401 15.3838 1 12.2498 1 8.38379Z"
                  fill="white"
                />
                <path
                  d="M1 8.38379C1 4.5178 4.13401 1.38379 8 1.38379C11.866 1.38379 15 4.5178 15 8.38379C15 12.2498 11.866 15.3838 8 15.3838C4.13401 15.3838 1 12.2498 1 8.38379Z"
                  stroke="#D5D5D5"
                  strokeWidth="2"
                />
              </svg>
            </SvgIcon>
          }
          checkedIcon={
            <SvgIcon sx={{ width: "16px", height: "16px" }}>
              <svg
                width="16"
                height="19"
                viewBox="0 0 16 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1148_8998)">
                  <path
                    d="M2.5 10.3838C2.5 7.34622 4.96243 4.88379 8 4.88379C11.0376 4.88379 13.5 7.34622 13.5 10.3838C13.5 13.4214 11.0376 15.8838 8 15.8838C4.96243 15.8838 2.5 13.4214 2.5 10.3838Z"
                    fill="white"
                  />
                  <path
                    d="M2.5 10.3838C2.5 7.34622 4.96243 4.88379 8 4.88379C11.0376 4.88379 13.5 7.34622 13.5 10.3838C13.5 13.4214 11.0376 15.8838 8 15.8838C4.96243 15.8838 2.5 13.4214 2.5 10.3838Z"
                    stroke={theme.palette.app.accent}
                    strokeWidth="5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1148_8998">
                    <rect
                      width="16"
                      height="18"
                      fill="white"
                      transform="translate(0 0.383789)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </SvgIcon>
          }
        />
      }
      label={<Text text={label} />}
    />
  );
};

export default RadioInput;
