import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import debounce from "debounce";
import { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FormContainer from "src/components/Displays/FormContainer";
import CurrentSupplierInputsForm from "src/components/Forms/CurrentSupplierInputsForm";
import Page from "src/components/Layout/Page";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { ROUTES } from "src/consts/routes";
import { currentSupplierInputsFormSchema } from "src/schema/currentSupplierInputsFormSchema";
import { energyProviders } from "src/api/apiClients";
import { energyProvidersMapped } from "src/dto/clientsModelMapped";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { SESSION_KEYS, SITE_TECHNICAL_DATA } from "src/consts/session";
import { Tariff } from "src/models/clientsModel";

export interface CurrentSupplierFormValues {
  energyProvider: string;
  energyProvidersList: {
    defaultList: string[];
    filteredList: string[];
  };
}

const CurrentSupplier = () => {
  const { t } = useTranslation();
  const [getTariffs] = useSessionStorage<Tariff[]>(SESSION_KEYS.TARIFFS);
  const tariffProviderDisplayName =
    getTariffs()?.[0]?.tariffProviderDisplayName || "";
  const navigate = useNavigate();
  const pageTitle = t("page.currentSupplier.pageTitle");
  const pageSubTitle = t("page.currentSupplier.pageSubTitle");
  const formTitle = t("page.currentSupplier.formTitle");
  const formSubTitle = t("page.currentSupplier.formSubTitle", {
    tariffProviderDisplayName,
  });

  const [energyProvidersListWithUuid, setEnergyProvidersListWithUuid] =
    useState<
      {
        uuid: string;
        displayName: string;
      }[]
    >([]);

  const [getSessionStorageItem, setSessionStorageItem] =
    useSessionStorage<SITE_TECHNICAL_DATA>(SESSION_KEYS.SITE_TECHNICAL_DATA);

  const { watch, reset, control, setError, getValues, handleSubmit } =
    useForm<CurrentSupplierFormValues>({
      defaultValues: {
        energyProvider: getSessionStorageItem()?.energyProvider ?? "",
        energyProvidersList: {
          defaultList: [],
          filteredList: [],
        },
      },
      mode: "onSubmit",
      resolver: yupResolver(currentSupplierInputsFormSchema),
    });

  const getEnergyProviders = useCallback(async () => {
    try {
      const response = await energyProviders();
      const responeMapped = energyProvidersMapped(response);
      reset({
        ...getValues(),
        energyProvidersList: {
          defaultList: responeMapped,
          filteredList: [],
        },
      });
      setEnergyProvidersListWithUuid(response.energyProviders);
    } catch (e) {}
  }, [reset, getValues]);

  useEffect(() => {
    getEnergyProviders();
  }, [getEnergyProviders]);

  useEffect(() => {
    const debouncedCb = debounce(async (value, { name }) => {
      if (name === "energyProvider") {
        reset({
          ...getValues(),
          energyProvidersList: {
            ...getValues().energyProvidersList,
            filteredList: [],
          },
        });

        if (value.energyProvider.length === 0) {
          setError("energyProvider", {
            message: "page.currentSupplier.formErrors.energyProvider",
          });
          return;
        }

        if (value.energyProvider.length < 3) return;

        const filteredList = getValues().energyProvidersList.defaultList.filter(
          (provider) => {
            return provider
              .toLowerCase()
              .startsWith(value.energyProvider.toLowerCase());
          }
        );
        if (filteredList.length > 10) filteredList.length = 10;

        reset({
          ...getValues(),
          energyProvidersList: {
            ...getValues().energyProvidersList,
            filteredList,
          },
        });
      }
    }, 500);
    const subscription = watch(debouncedCb);
    return () => subscription.unsubscribe();
  }, [watch, reset, getValues, setError]);

  const onSubmit = (data: CurrentSupplierFormValues) => {
    const foundProvider = getValues()
      .energyProvidersList.defaultList.map((v) => v.toLowerCase())
      .includes(data.energyProvider.trim().toLowerCase());

    if (!foundProvider) {
      setError("energyProvider", {
        message: "page.currentSupplier.formErrors.energyProvider",
      });
      return;
    }

    const foundEnergyProvider = energyProvidersListWithUuid.find(
      (provider) => provider.displayName === data.energyProvider.trim()
    );

    const sessionData = getSessionStorageItem();
    const updatedSessionData: SITE_TECHNICAL_DATA = {
      ...sessionData,
      energyProvider: data.energyProvider.trim(),
      energyProviderUuid: foundEnergyProvider?.uuid,
    };
    setSessionStorageItem(updatedSessionData);
    navigate(ROUTES.DATE_OF_CONTRACT_START);
  };

  const handleBackClick = () => {
    navigate(ROUTES.CONTRACT_CHANGE);
  };

  const filteredList = getValues().energyProvidersList.filteredList;

  return (
    <Page pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <FormContainer
        title={formTitle}
        subTitle={formSubTitle}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ marginTop: 6, marginBottom: 3 }}>
          <CurrentSupplierInputsForm control={control} options={filteredList} />
        </Box>

        <FormSubmitContainer onClick={handleBackClick} />
      </FormContainer>
    </Page>
  );
};

export default CurrentSupplier;
