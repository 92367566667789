import { Typography, styled } from "@mui/material";

interface InputLabelProps {
  text: string;
}

const InputLabel = ({ text }: InputLabelProps) => {
  const InputLabelStyles = styled(Typography)(({ theme }) => ({
    color: theme.palette.app.secondaryFont,
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "18px",
  }));

  return <InputLabelStyles>{text}</InputLabelStyles>;
};

export default InputLabel;
