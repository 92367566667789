import { Box, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckCircle } from "src/assets";
import ControlledAccordion from "src/components/Accordion/ControlledAccordion";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import Text from "src/components/Displays/Text";
import { Tariff } from "src/models/clientsModel";

interface IntelligentMeasuerProps {
  expanded: string | boolean;
  handleChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  tariffsData?: Tariff[];
}

const IntelligentMeasuer = ({
  expanded,
  handleChange,
  tariffsData,
}: IntelligentMeasuerProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const savingsData = tariffsData && tariffsData[0];
  const costSmartMeterSign =
    savingsData && savingsData.savings?.costSmartMeterSign;
  const costSmartMeterMonthly =
    savingsData && savingsData.savings?.costSmartMeterMonthly;
  const costSmartMeterYearly =
    savingsData && savingsData.savings?.costSmartMeterYearly;

  return (
    <ControlledAccordion
      title={t("page.tariff.intelligentMeasuer.title")}
      subtitleCollapsed={t("page.tariff.intelligentMeasuer.subtitle")}
      subtitleUnCollapsed={t("page.tariff.intelligentMeasuer.smartMeter")}
      backgroundColor={theme.palette.form.background}
      value={t("page.tariff.intelligentMeasuer.monthFormat", {
        costSmartMeterSign,
        costSmartMeterMonthly,
      })}
      expanded={expanded}
      handleChange={handleChange}
      name="intelligentMeasuer"
    >
      <Stack direction="row" justifyContent="space-between">
        <Text
          fontWeight={700}
          sx={{
            fontSize: "11px",
            lineHeight: "18px",
          }}
          text={t("page.tariff.intelligentMeasuer.year")}
        />
        <Text
          fontWeight={700}
          sx={{
            fontSize: "11px",
            lineHeight: "18px",
          }}
          text={t("page.tariff.intelligentMeasuer.yearFormat", {
            costSmartMeterSign,
            costSmartMeterYearly,
          })}
        />
      </Stack>
      <Stack
        gap={3}
        sx={{
          mt: 6,
        }}
      >
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.intelligentMeasuer.surplus")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.intelligentMeasuer.costs")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.intelligentMeasuer.contractTerm")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.intelligentMeasuer.noticePeriod")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.intelligentMeasuer.billingMonthly")}
          />
        </Stack>
      </Stack>
    </ControlledAccordion>
  );
};

export default IntelligentMeasuer;
