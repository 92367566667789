import { UseFormReturn } from "react-hook-form";
import RadioGroupInput from "src/components/Inputs/RadioGroupInput";
import RadioInput from "src/components/Inputs/RadioInput";
import { useTranslation } from "react-i18next";
import { Stack, useTheme } from "@mui/material";
import { SmartMeterFormValues, SmartMeterTypes } from "src/pages/SmartMeter";
import LabelTitle from "../../Displays/LabelTitle";
import { QuestionMark } from "src/assets";
import { useToggle } from "src/hooks/useToggle";
import Modal from "../../Modal/Modal";
import PageTitle from "../../Displays/PageTitle";
import SmartMeterModalContent from "./SmartMeterModalContent";

interface SmartMeterFormProps {
  form: UseFormReturn<SmartMeterFormValues, any, undefined>;
}

const SmartMeterForm = ({ form }: SmartMeterFormProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isOverlayOpen, toggleIsOverlayOpen] = useToggle(false);

  return (
    <>
      <Modal
        title={
          <PageTitle
            color={theme.palette.grey[500]}
            text={t("page.smartMeter.modal.header")}
          />
        }
        isOpen={isOverlayOpen}
        onClose={toggleIsOverlayOpen}
        onSubmit={toggleIsOverlayOpen}
      >
        <SmartMeterModalContent />
      </Modal>
      <RadioGroupInput
        sx={{
          gap: 3,
        }}
        control={form.control}
        name="smartMeterInstalled"
      >
        <Stack direction="row" gap={1}>
          <LabelTitle text={t("page.smartMeter.formLabel")} />
          <Stack
            sx={{
              cursor: "pointer",
            }}
          >
            <QuestionMark onClick={toggleIsOverlayOpen} />
          </Stack>
        </Stack>
        <Stack
          sx={(theme) => ({
            borderRadius: 2,
            border:
              form.watch("smartMeterInstalled") === SmartMeterTypes.NEIN
                ? `1px solid ${theme.palette.grey[100]}`
                : "none",
          })}
        >
          <RadioInput
            value={SmartMeterTypes.NEIN}
            label={t("page.smartMeter.no")}
            labelPlacement="start"
            sx={(theme) => ({
              borderRadius: 2,
              border:
                form.watch("smartMeterInstalled") === SmartMeterTypes.NEIN
                  ? "none"
                  : `1px solid ${theme.palette.grey[100]}`,
              p: 4,
              display: "flex",
              justifyContent: "space-between",
              m: 0,
            })}
          />
          {form.watch("smartMeterInstalled") === SmartMeterTypes.NEIN && (
            <Stack
              gap={2}
              sx={{
                px: 4,
                pb: 4,
              }}
            >
              <Stack
                sx={(theme) => ({
                  p: 3,
                  borderRadius: 2,
                  backgroundColor: theme.palette.grey[50],
                })}
                gap={1}
              >
                <LabelTitle text={t("page.smartMeter.boxtTitle")} />
                <LabelTitle
                  fontWeight={400}
                  text={t("page.smartMeter.boxText")}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
        <RadioInput
          value={SmartMeterTypes.JA}
          label={t("page.smartMeter.yes")}
          labelPlacement="start"
          sx={(theme) => ({
            borderRadius: 2,
            border: `1px solid ${theme.palette.grey[100]}`,
            p: 4,
            display: "flex",
            justifyContent: "space-between",
            m: 0,
          })}
        />
      </RadioGroupInput>
    </>
  );
};

export default SmartMeterForm;
