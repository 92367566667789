import FormContainer from "src/components/Displays/FormContainer";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import YourNameForm from "src/components/Forms/YourNameInputsForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { yourNameInputsFormSchema } from "src/schema/yourNameInputsFormSchema";
import { useTranslation } from "react-i18next";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import Page from "src/components/Layout/Page";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/consts/routes";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { SESSION_KEYS, USER_PROFILE_DATA } from "src/consts/session";
import { useEffect } from "react";
export interface YourNameFormValues {
  firstName: string;
  lastName: string;
}

const YourName = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formTitle = t("page.yourName.formTitle");
  const pageTitle = t("page.yourName.pageTitle");
  const pageSubTitle = t("page.yourName.pageSubTitle");

  const [getSessionStorageItem, setSessionStorageItem] =
    useSessionStorage<USER_PROFILE_DATA>(SESSION_KEYS.USER_PROFILE_DATA);

  const { reset, control, handleSubmit } = useForm<YourNameFormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(yourNameInputsFormSchema),
  });

  useEffect(() => {
    const localData = getSessionStorageItem();
    reset({
      firstName: localData?.firstName,
      lastName: localData?.lastName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const onSubmit = (data: YourNameFormValues) => {
    const sessionData = getSessionStorageItem();
    const updatedSessionData: USER_PROFILE_DATA = {
      ...sessionData,
      firstName: data.firstName,
      lastName: data.lastName,
    };
    setSessionStorageItem(updatedSessionData);
    navigate(ROUTES.CONTACT_INFORMATION);
  };

  const handleBackClick = () => {
    navigate(ROUTES.TARIF);
  };

  return (
    <Page pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <FormContainer title={formTitle} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ marginTop: 6, marginBottom: 3 }}>
          <YourNameForm control={control} />
        </Box>
        <FormSubmitContainer onClick={handleBackClick} />
      </FormContainer>
    </Page>
  );
};

export default YourName;
