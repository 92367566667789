import { Typography, TypographyProps, styled } from "@mui/material";

interface FormHeaderProps extends TypographyProps {
  text: string;
  color?: string;
}

const FormHeader = ({ text, color, sx }: FormHeaderProps) => {
  const FormTitleStyles = styled(Typography)(({ theme }) => ({
    color: color || theme.palette.app.secondaryFont,
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
  }));

  return (
    <FormTitleStyles variant="h2" sx={sx} mt={2}>
      {text}
    </FormTitleStyles>
  );
};

export default FormHeader;
