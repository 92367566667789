import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as de from "src/locales/de/translation.json";

export const defaultNS = "translation";

export const resources = {
  de,
};

i18n.use(initReactI18next).init({
  resources,
  defaultNS,
  fallbackLng: "de",
});

export default i18n;
