import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MeterNumber } from "src/assets";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import ProgressBarTitle from "src/components/Displays/ProgressBarTitle";

const MeterNumberModalContent = () => {
  const { t } = useTranslation();
  return (
    <Stack gap={4}>
      <Stack gap={4}>
        <ProgressBarTitle
          color="#222"
          text={t("page.technicalDetails.meterNumber.inforFirstParagraph")}
        />
        <Stack>
          <ProgressBarTitle
            color="#222"
            text={t("page.technicalDetails.meterNumber.infoSecondParagraph")}
          />
          <ProgressBarTitle
            color="#222"
            text={t("page.technicalDetails.meterNumber.infoThirdParagraph")}
          />
        </Stack>
        <MeterNumber />
        <PageSubTitle
          color="#222"
          text={t("page.technicalDetails.meterNumber.imageAlt")}
        />
      </Stack>
      <ProgressBarTitle
        color="#222"
        text={t("page.technicalDetails.meterNumber.infoForthParagraph")}
      />
    </Stack>
  );
};

export default MeterNumberModalContent;
