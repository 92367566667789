import { useState } from "react";
import { ThemeName } from "../ThemeUsage/theme";
import { Disco, Logo } from "src/assets";

interface BrandItemThemeChangerProps {
  color?: string;
}

const BrandItemThemeChanger = ({ color }: BrandItemThemeChangerProps) => {
  const [themeName] = useState<ThemeName>(
    (sessionStorage.getItem("theme") || "default") as ThemeName
  );

  return themeName === "disco" ? (
    <Disco color={color} />
  ) : (
    <Logo color={color} />
  );
};

export default BrandItemThemeChanger;
