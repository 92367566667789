import {
  SESSION_KEYS,
  SITE_TECHNICAL_DATA,
  USER_PROFILE_DATA,
} from "src/consts/session";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { Tariff } from "src/models/clientsModel";

const useGetContractSummaryData = () => {
  const [getSessionStorageItem] = useSessionStorage<Tariff[]>(
    SESSION_KEYS.TARIFFS
  );
  const [getUserSessionStorageItem] = useSessionStorage<USER_PROFILE_DATA>(
    SESSION_KEYS.USER_PROFILE_DATA
  );
  const [getSiteSessionStorageItem] = useSessionStorage<SITE_TECHNICAL_DATA>(
    SESSION_KEYS.SITE_TECHNICAL_DATA
  );

  const tariffProviderDisplayName =
    getSessionStorageItem()?.[0].tariffProviderDisplayName || "LichtBlick";

  const tariffDisplayName =
    getSessionStorageItem()?.[0].tariffDisplayName || "StromWallet";

  const street = getUserSessionStorageItem()?.street || "";
  const houseNumber = getUserSessionStorageItem()?.houseNumber || "";
  const zipCode = getUserSessionStorageItem()?.zipCode || "";
  const city = getUserSessionStorageItem()?.city || "";
  const mobileNumber = getUserSessionStorageItem()?.mobileNumber || "";
  const email = getUserSessionStorageItem()?.email || "";
  const marketLocationID = getSiteSessionStorageItem()?.marketLocationId || "-";
  const meterNumber = getSiteSessionStorageItem()?.meterNumber || "-";
  const cancelContractType =
    getSiteSessionStorageItem()?.cancelContractType || "";
  const energyProvider = getSiteSessionStorageItem()?.energyProvider || "";
  const contractStartDate =
    getSiteSessionStorageItem()?.contractStartDate || "";
  const paymentAccout =
    getSiteSessionStorageItem()?.paymentDetails?.account || "";
  const cardHolder =
    getSiteSessionStorageItem()?.paymentDetails?.cardHolder || "";
  const iban = getSiteSessionStorageItem()?.paymentDetails?.iban || "";

  return {
    tariffProviderDisplayName,
    tariffDisplayName,
    street,
    houseNumber,
    zipCode,
    city,
    mobileNumber,
    email,
    marketLocationID,
    meterNumber,
    cancelContractType,
    energyProvider,
    contractStartDate,
    paymentAccout,
    cardHolder,
    iban,
  };
};

export default useGetContractSummaryData;
