import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

const VerticalLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  borderRadius: 5,
  width: "8px",
  height: "100%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.progressBar.background,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.progressBar.primary,
    transform: `translateY(-${value}%) !important`,
  },
}));

export default VerticalLinearProgress;
