import { Typography, TypographyProps, styled } from "@mui/material";
import { CSSProperties } from "react";

interface LabelTitleProps extends TypographyProps {
  text: string;
  fontWeight?: CSSProperties["fontWeight"];
}

const LabelTitle = ({
  text,
  fontWeight = "700",
  ...props
}: LabelTitleProps) => {
  const LabelTitleStyles = styled(Typography)(({ theme }) => ({
    color: theme.palette.app.secondaryFont,
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: fontWeight,
    lineHeight: "18px",
  }));

  return <LabelTitleStyles {...props}>{text}</LabelTitleStyles>;
};

export default LabelTitle;
