export const ROUTES = {
  INTRODUCTION: "/",

  PV_SETUP: "/pv-setup",
  WALLBOX: "/wallbox-setup",
  BATTERY_SETUP: "/battery-setup",
  ANNUAL_CONSUMPTION: "/annual-consumption",
  SMART_METER: "/smart-meter",
  CALCULATED_SAVINGS: "/calculated-savings",
  TARIF: "/tarif-selection",

  YOUR_NAME: "/your-name",
  CONTACT_INFORMATION: "/contact-information",
  ADDRESS: "/address",
  TECHNICAL_DETAILS: "/technical-details",

  CONTRACT_CHANGE: "/contract-change",
  CURRENT_SUPPLIER: "/current-supplier",
  DATE_OF_CONTRACT_START: "/date-of-contract-start",

  PAYMENT_DETAILS: "/payment-details",
  CONTRACT_SUMMARY: "/contract-summary",

  CONCLUSION: "/conclusion",
  ERROR: "/error",
};
