import { Theme } from "@mui/material";
import { defaultTheme, discoTheme } from "./themes";

export type ThemeTypes = {
  default: Theme;
  disco: Theme;
};

export type ThemeName = "disco" | "default";

export const THEMES = {
  default: defaultTheme,
  disco: discoTheme,
};
