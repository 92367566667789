import { Control } from "react-hook-form";
import { CurrentSupplierFormValues } from "src/pages/CurrentSupplier";
import { useTranslation } from "react-i18next";
import InputWrapper from "src/components/Inputs/InputWrapper";

import SearchInput from "src/components/Inputs/SearchInput";
interface CurrentSupplierInputsFormProps {
  control: Control<CurrentSupplierFormValues>;
  options: string[];
}

const CurrentSupplierInputsForm = ({
  control,
  options,
}: CurrentSupplierInputsFormProps) => {
  const { t } = useTranslation();
  const energyProviderLabel = t(
    "page.currentSupplier.formFields.energyProvider.label"
  );
  const energyProviderPlaceholder = t(
    "page.currentSupplier.formFields.energyProvider.placeholder"
  );

  return (
    <InputWrapper>
      <SearchInput
        fullWidth
        control={control}
        name="energyProvider"
        labelText={energyProviderLabel}
        options={options}
        placeholder={energyProviderPlaceholder}
      />
    </InputWrapper>
  );
};

export default CurrentSupplierInputsForm;
