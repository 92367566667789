import { Typography, TypographyProps, styled } from "@mui/material";

interface TextSubtitleProps extends TypographyProps {
  text: string;
  color?: string;
}

const TextSubtitle = ({ text, color, ...props }: TextSubtitleProps) => {
  const TextSubtitleStyles = styled(Typography)(({ theme }) => ({
    color: color || theme.palette.app.secondaryFont,
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "30px",
  }));

  return <TextSubtitleStyles {...props}>{text}</TextSubtitleStyles>;
};

export default TextSubtitle;
