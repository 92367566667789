import { Box, Stack } from "@mui/material";
import BackButton from "./BackButton";
import SubmitButton from "./SubmitButton";

interface FormSubmitContainerProps {
  onClick: () => void;
  isLoading?: boolean;
}

const FormSubmitContainer = ({
  onClick,
  isLoading,
}: FormSubmitContainerProps) => {
  return (
    <>
      <Box height={16} />
      <Stack direction={"row"} spacing={2}>
        <BackButton variant="dark" onClick={onClick} />
        <SubmitButton type="submit" fullWidth isLoading={isLoading} />
      </Stack>
    </>
  );
};

export default FormSubmitContainer;
