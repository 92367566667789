export const REGEX = {
  CONTAINS_NUMBERS: /\d+/,
  ONLY_NUMBERS: /^\d+$/,
  EMAIL:
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
  STREET: /^[-&'/.äöüßäàáâæãåāçćčéèêëėîïíīìñńöôòóõœøōśšüûùúūÿ a-zA-Z0-9]+$/,
  HOUSENUMBER: /^[-/().a-zA-Z0-9]+( [-/().a-zA-Z0-9]+)*$/,
  ZIP_CODE: /^[1-9]\d*$/g,
  CITY: /^[üäößäöüßäàáâæãåāçćčéèêëėîïíīìñńöôòóõœøōśšüûùúūÿa-zA-Z0-9 \-/()]+$/,
  ALPHA_NUMERIC: /^([a-zA-Z0-9]+)$/,
  LETTERS: /^[a-zA-Z]*$/,
  CARD_HOLDER: /^[\u00c0-\u01ffa-zA-Z'-]+( [\u00c0-\u01ffa-zA-Z'-]+)*$/,
  DE_IBAN: /^(?:\s*\d\s*){20}$/,
  MARKET_LOCATION_ID: /^[1-9]\d*$/g,
  IS_DECIMAL: /^\d+(\.\d{1,2})?$/,
  ONLY_NUMBERS_WITH_COMMA: /^[0-9]*,?[0-9]*$/,
};
