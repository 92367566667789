import * as yup from "yup";
import { ContactInformationFormValues } from "src/pages/ContactInformation";
import { REGEX } from "src/consts/regex";

export const contactInformationInputsFormSchema: yup.ObjectSchema<ContactInformationFormValues> =
  yup.object({
    mobileNumber: yup
      .string()
      .required("page.contactInformation.formErrors.mobileNumber")
      .min(8, "page.contactInformation.formErrors.mobileNumber")
      .max(15, "page.contactInformation.formErrors.mobileNumber")
      .matches(
        REGEX.ONLY_NUMBERS,
        "page.contactInformation.formErrors.mobileNumber"
      ),

    email: yup
      .string()
      .min(3, "page.contactInformation.formErrors.email")
      .max(320, "page.contactInformation.formErrors.email")
      .required("page.contactInformation.formErrors.email")
      .matches(REGEX.EMAIL, "page.contactInformation.formErrors.email"),

    confirmEmail: yup
      .string()
      .required("page.contactInformation.formErrors.confirmEmail")
      .test(
        "email-match",
        "page.contactInformation.formErrors.confirmEmail",
        function (value) {
          return this.parent.email === value;
        }
      ),
  });
