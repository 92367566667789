import { SmartMeterFormValues, SmartMeterTypes } from "src/pages/SmartMeter";
import * as yup from "yup";

export const smartMeterFormSchema: yup.ObjectSchema<SmartMeterFormValues> =
  yup.object({
    smartMeterInstalled: yup
      .mixed<SmartMeterTypes>()
      .notOneOf(
        ["" as SmartMeterTypes.BLANK],
        "page.smartMeter.formError.required"
      )
      .required(),
  });
