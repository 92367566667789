import { Box, Stack } from "@mui/material";
import { ReactNode } from "react";
import PageTitle from "src/components/Displays/PageTitle";
import PageSubTitle from "src/components/Displays/PageSubTitle";

interface SplinePageProps {
  children: ReactNode;
  pageTitle: string;
  pageSubTitle?: string;
}

const SplinePage = ({ children, pageTitle, pageSubTitle }: SplinePageProps) => {
  return (
    <>
      <Stack
        gap={2}
        sx={{
          mx: { xs: 4, sm: 0 },
          mt: { xs: 6, sm: 0 },
        }}
      >
        {pageTitle && <PageTitle text={pageTitle} />}
        {pageSubTitle && (
          <>
            <Box height={8} />
            <PageSubTitle text={pageSubTitle} />
          </>
        )}
        <Box height={24} />
      </Stack>

      <Box
        sx={{
          width: { xs: "100%", lg: "605px" },
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default SplinePage;
