import { Button, styled, ButtonProps } from "@mui/material";

interface Props extends ButtonProps {
  text: string | React.ReactNode;
}

const PrimaryButtonStyles = styled(Button)(({ theme }) => ({
  color: theme.palette.primaryButton.text,
  backgroundColor: theme.palette.primaryButton.background,
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "22px",
  borderRadius: "32px",
  "&:hover": {
    backgroundColor: theme.palette.primaryButton.background,
  },
  padding: "12px 24px 12px 24px",
  textTransform: "capitalize",
}));

const PrimaryButton = ({ text, ...props }: Props) => (
  <PrimaryButtonStyles {...props} disableRipple>
    {text}
  </PrimaryButtonStyles>
);

export default PrimaryButton;
