import * as yup from "yup";
import { CurrentSupplierFormValues } from "src/pages/CurrentSupplier";

export const currentSupplierInputsFormSchema: yup.ObjectSchema<CurrentSupplierFormValues> =
  yup.object({
    energyProvider: yup
      .string()
      .required("page.currentSupplier.formErrors.energyProvider"),
    energyProvidersList: yup.object({
      defaultList: yup.array().required(),
      filteredList: yup.array().required(),
    }),
  });
