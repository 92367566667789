import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import ThemeUsage from "src/components/ThemeUsage/ThemeUsage";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import ScrollToTop from "src/components/Shared/ScrollToTop";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeUsage>
        <ScrollToTop />
        <App />
      </ThemeUsage>
    </BrowserRouter>
  </React.StrictMode>
);
