import { Button, styled, ButtonProps } from "@mui/material";

interface Props extends ButtonProps {
  text: string | React.ReactNode;
}

const SecondaryButtonStyles = styled(Button)(({ theme }) => ({
  color: theme.palette.secondaryButton.text,
  backgroundColor: theme.palette.secondaryButton.background,
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "22px",
  borderRadius: "32px",
  "&:hover": {
    backgroundColor: theme.palette.secondaryButton.background,
  },
  padding: "12px 24px 12px 24px",
  textTransform: "capitalize",
}));

const SecondaryButton = ({ text, ...props }: Props) => (
  <SecondaryButtonStyles {...props} disableRipple>
    {text}
  </SecondaryButtonStyles>
);

export default SecondaryButton;
