import ContentCard from "src/components/Shared/ContentCard";
import FormTitle from "src/components/Displays/FormTitle";
import FormSubTitle from "src/components/Displays/FormSubTitle";
import { FormEventHandler, ReactNode } from "react";
import { Box } from "@mui/material";

interface FormContainerProps {
  title: string;
  subTitle?: string;
  children: ReactNode;
  onSubmit: FormEventHandler<HTMLFormElement> | undefined;
}

const FormContainer = ({
  title,
  subTitle,
  children,
  onSubmit,
}: FormContainerProps) => {
  return (
    <ContentCard>
      <FormTitle text={title} />
      <FormSubTitle text={subTitle} />
      <Box component={"form"} autoComplete="off" onSubmit={onSubmit}>
        {children}
      </Box>
    </ContentCard>
  );
};

export default FormContainer;
