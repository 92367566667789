import { Card } from "@mui/material";

interface ContentCardProps {
  children: React.ReactNode;
}

const ContentCard = ({ children }: ContentCardProps) => {
  return (
    <Card
      sx={(theme) => ({
        padding: 3,
        borderRadius: 2,
        boxShadow: "0px 1px 2px 0px #00000040",
        backgroundColor: theme.palette.form.background,
      })}
    >
      {children}
    </Card>
  );
};

export default ContentCard;
