import { Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TodayPrice } from "src/assets";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import ProgressBarTitle from "src/components/Displays/ProgressBarTitle";

const ModalContent = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack gap={4}>
      <ProgressBarTitle
        color={theme.palette.grey[500]}
        text={t("page.tariff.modal.content")}
      />
      <Stack gap={4}>
        <TodayPrice />
        <PageSubTitle
          color={theme.palette.grey[400]}
          text={t("page.tariff.modal.subtitle")}
        />
      </Stack>
    </Stack>
  );
};

export default ModalContent;
