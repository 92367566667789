export enum ScreenSizes {
  XS = 0,
  SM = 600,
  MD = 900,
  LG = 1200,
  XL = 1536,
}

interface MainFont {
  primary: string;
}

interface Form {
  background: string;
}

interface ProgressBar {
  primary: string;
  background: string;
  activeLinks: string;
  inactiveLinks: string;
  border: string;
}

interface PrimaryButton {
  text: string;
  background: string;
}

interface SecondaryButton {
  text: string;
  background: string;
}

interface OutlineButton {
  text: string;
}

interface App {
  primary: string;
  oppositePrimary: string;
  contrast: string;
  secondary: string;
  primaryFont: string;
  secondaryFont: string;
  accent: string;
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    green: PaletteColor;
    white: PaletteColor;
    yellow: PaletteColor;
    lila: PaletteColor;
    mainFont: MainFont;
    progressBar: ProgressBar;
    primaryButton: PrimaryButton;
    secondaryButton: SecondaryButton;
    outlineButton: OutlineButton;
    form: Form;
    app: App;
  }

  interface PaletteColor {
    50?: string;
    100?: string;
    main: string;
  }

  interface PaletteOptions {
    white: PaletteColorOptions;
    yellow: PaletteColorOptions;
    lila: PaletteColorOptions;
    red: PaletteColorOptions;
    green: PaletteColorOptions;
    mainFont: MainFont;
    progressBar: ProgressBar;
    primaryButton: PrimaryButton;
    secondaryButton: SecondaryButton;
    outlineButton: OutlineButton;
    form: Form;
    app: App;
  }
}
