export const numberFormat = (str: string) => {
  const findArray = [",", "."];
  const replaceArray = [".", ","];

  let i: number;
  let regex: string[] = [];
  let map: any = {};

  for (i = 0; i < findArray.length; i++) {
    regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, "\\$1"));
    map[findArray[i]] = replaceArray[i];
  }

  const updatedRegex = regex.join("|");

  str = str.replace(new RegExp(updatedRegex, "g"), function (matched: string) {
    return map[matched];
  });

  return str;
};
