import { Stack, useTheme } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import FormContainer from "src/components/Displays/FormContainer";
import { ROUTES } from "src/consts/routes";

import Modal from "src/components/Modal/Modal";
import PageTitle from "src/components/Displays/PageTitle";
import { useToggle } from "src/hooks/useToggle";
import ModalContent from "./ModalContent";
import EconomyBox from "./EconomyBox";
import CalculatedSavingsForm from "src/components/Forms/CalculatedSavingsForm";
import { useTranslation } from "react-i18next";
import { GlobalContextProps } from "src/components/GlobalContext/GlobalContext";

const CalculatedSavings = () => {
  const [isOverlayOpen, toggleIsOverlayOpen] = useToggle(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { setAnimations } = useOutletContext<GlobalContextProps>();

  const handleSubmit = () => {
    setAnimations((prev) => ({ ...prev, continue: !prev.continue }));
    navigate(ROUTES.TARIF);
  };

  const handleBackClick = () => {
    navigate(ROUTES.PV_SETUP);
  };

  return (
    <Stack gap={6}>
      <Modal
        title={
          <PageTitle
            color={theme.palette.grey[500]}
            text={t("page.calculatedSavings.modal.title")}
          />
        }
        isOpen={isOverlayOpen}
        onClose={toggleIsOverlayOpen}
        onSubmit={toggleIsOverlayOpen}
      >
        <ModalContent />
      </Modal>
      <EconomyBox handleSubmit={handleSubmit} />
      <FormContainer
        title={t("page.calculatedSavings.formTitle")}
        onSubmit={() => {}}
      >
        <CalculatedSavingsForm
          handleBackClick={handleBackClick}
          toggleIsOverlayOpen={toggleIsOverlayOpen}
        />
      </FormContainer>
    </Stack>
  );
};

export default CalculatedSavings;
