import { callApi } from "./callApi";
import {
  LeadTransfer,
  TariffSavings,
  EnergyProviders,
  FunnelOrder,
} from "src/models/clientsModel";

export const leadTransfer = ({
  transferId,
  salesPartnerUuid,
}: {
  transferId: string;
  salesPartnerUuid: string;
}): Promise<LeadTransfer> => {
  return callApi({
    path: `/api/v1/lead/transfer/${salesPartnerUuid}?transfer_id=${transferId}`,
  });
};

export interface TariffSavingsPayload {
  siteTechnicalData: {
    wallboxInstalled: boolean;
    pvInstalled: boolean;
    batteryInstalled: boolean;
    smartMeterInstalled: boolean;
    pvPowerMaxKwp: number | null;
    batterySizeKwh: number | null;
  };
  userProfileData: {
    yearlyConsumptionKwh: number;
    zipcode: number | null;
  };
}

export const tariffSavings = (
  payload: TariffSavingsPayload
): Promise<TariffSavings> => {
  let transferId = sessionStorage.getItem("transferId") ?? "";
  if (transferId.length !== 0) {
    transferId = JSON.parse(transferId);
  }
  return callApi({
    path: `/api/v1/tariff/savings`,
    method: "POST",
    payload,
    transferId,
  });
};

export const energyProviders = (): Promise<EnergyProviders> => {
  const energyServiceFlag = process.env.REACT_APP_ENERGY_SERVICE_FLAG;
  return callApi({ path: "/api/v2/energy/providers", energyServiceFlag });
};

export interface FunnelOrderPayload {
  siteTechnicalData: {
    pvInstalled: boolean;
    pvPowerMaxKwp: number | null;
    wallboxInstalled: boolean;
    batteryInstalled: boolean;
    batterySizeKwh: number | null;
    smartMeterInstalled: boolean;
  };

  userProfileData: {
    yearlyConsumptionKwh: number;
    firstname: string;
    lastname: string;
    country: string;
    zipcode: number;
    city: string;
    street: string;
    streetNumber: string;
    emailAddress: string;
    mobilePhone: string;
    invoiceStreet: string;
    invoiceStreetNumber: string;
    invoiceZipcode: number;
    invoiceCity: string;
  };

  contractDetailData: {
    utilityToCancelExistingContract: boolean;
    newContractStartDate: string | null;
    energyProviderUuid: string | null;
    paymentMethod: string;
    bankAccountHolder: string | null;
    iban: string | null;
    agbSmartMeterAccepted: boolean;
    agbEnergyProviderAccepted: boolean;
    tariffLogicalId: string;
  };
}

export const funnelOrder = (
  payload: FunnelOrderPayload
): Promise<FunnelOrder> => {
  let transferId = sessionStorage.getItem("transferId") ?? "";
  if (transferId.length !== 0) {
    transferId = JSON.parse(transferId);
  }
  return callApi({
    path: "/api/v1/funnel/order",
    method: "POST",
    payload,
    transferId,
  });
};
