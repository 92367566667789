import { Box, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckCircle, QuestionMark } from "src/assets";
import ControlledAccordion from "src/components/Accordion/ControlledAccordion";
import InputLabel from "src/components/Displays/InputLabel";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import Text from "src/components/Displays/Text";
import { Tariff } from "src/models/clientsModel";

interface ElectricityCostProps {
  toggleIsOverlayOpen: () => void;
  expanded: string | boolean;
  handleChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  tariffsData?: Tariff[];
}

const ElectricityCost = ({
  expanded,
  handleChange,
  toggleIsOverlayOpen,
  tariffsData,
}: ElectricityCostProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const savingsData = tariffsData && tariffsData[0];
  const costGridSupplySign =
    savingsData && savingsData.savings?.costGridSupplySign;
  const costGridSupplyMonthly =
    savingsData && savingsData.savings?.costGridSupplyMonthly;
  const costGridSupplyYearly =
    savingsData && savingsData.savings?.costGridSupplyYearly;

  return (
    <ControlledAccordion
      title={t("page.tariff.electricity.title")}
      subtitleCollapsed={t("page.tariff.electricity.subtitle")}
      subtitleUnCollapsed={t("page.tariff.electricity.dynamicPro")}
      backgroundColor={theme.palette.form.background}
      value={t("page.tariff.electricity.monthFormat", {
        costGridSupplySign,
        costGridSupplyMonthly,
      })}
      expanded={expanded}
      handleChange={handleChange}
      name="electricityCost"
    >
      <Stack direction="row" justifyContent="space-between">
        <Text
          fontWeight={700}
          sx={{
            fontSize: "11px",
            lineHeight: "18px",
          }}
          text={t("page.tariff.electricity.year")}
        />
        <Text
          fontWeight={700}
          sx={{
            fontSize: "11px",
            lineHeight: "18px",
          }}
          text={t("page.tariff.electricity.yearFormat", {
            costGridSupplySign,
            costGridSupplyYearly,
          })}
        />
      </Stack>
      <Stack
        gap={3}
        sx={{
          mt: 6,
        }}
      >
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.electricity.dynamicTariff")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.electricity.description")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.electricity.priceGuarantee")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.electricity.basicPrice")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.electricity.contractTerm")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.electricity.noticePeriod")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.electricity.billingMonthly")}
          />
        </Stack>
        <Stack direction="row" gap={2} onClick={toggleIsOverlayOpen}>
          <InputLabel text={t("page.tariff.electricity.dynamicTariffButton")} />
          <Box>
            <QuestionMark />
          </Box>
        </Stack>
      </Stack>
    </ControlledAccordion>
  );
};

export default ElectricityCost;
