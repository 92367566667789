import { Link, Stack, useTheme } from "@mui/material";
import Text from "../Displays/Text";
import PageSubTitle from "../Displays/PageSubTitle";
import { useTranslation } from "react-i18next";
import BrandItemThemeChanger from "../BrandIconThemeChanger/BrandIconThemeChanger";

const FooterLinks = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack gap={6} sx={{ bottom: 0, mt: "auto" }}>
      <Stack direction="row" alignItems="center" gap={2}>
        <Text
          text={t("footer.poweredBy")}
          fontWeight={700}
          color={theme.palette.app.primaryFont}
        />
        <BrandItemThemeChanger color={theme.palette.app.primaryFont} />
      </Stack>
      <Stack direction="row" flexWrap={"wrap"} gap={3} mb={2}>
        <Link
          sx={(theme) => ({
            color: theme.palette.primary.main,
            textDecoration: "none",
          })}
          href="https://ison.energy/en/legal-notice/"
          target="_blank"
        >
          <PageSubTitle text={t("footer.legalNotice")} />
        </Link>
        <Link
          sx={(theme) => ({
            color: theme.palette.primary.main,
            textDecoration: "none",
          })}
          href="https://ison.energy/en/privacy/"
          target="_blank"
        >
          <PageSubTitle text={t("footer.privacy")} />
        </Link>
        <Link
          sx={(theme) => ({
            color: theme.palette.primary.main,
            textDecoration: "none",
            fontSize: "14px",
          })}
          href="https://ison.energy/#contact"
          target="_blank"
        >
          <PageSubTitle text={t("footer.contact")} />
        </Link>
        <Link
          sx={(theme) => ({
            color: theme.palette.primary.main,
            textDecoration: "none",
          })}
          href="https://www.lichtblick.de/vertrag-kuendigen/"
          target="_blank"
        >
          <PageSubTitle text={t("footer.terminateContract")} />
        </Link>
      </Stack>
    </Stack>
  );
};

export default FooterLinks;
