import {
  Box,
  TextField,
  TextFieldProps,
  Autocomplete,
  Paper,
} from "@mui/material";
import {
  Controller,
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import InputLabel from "src/components/Displays/InputLabel";
import InputHelperText from "src/components/Displays/InputHelperText";
import { useTranslation } from "react-i18next";

interface SearchInputProps<T extends FieldValues>
  extends UseControllerProps<T> {
  labelText: string;
  options: string[];
}

const TextFieldStyles = {
  borderRadius: "8px",
  color: "grey.400",
  fontFamily: "Inter",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
  padding: "12px 16px 12px 16px",
};

const SearchInput = <T extends FieldValues>({
  name,
  control,
  labelText,
  helperText,
  options,
  placeholder,
  ...props
}: SearchInputProps<T> & TextFieldProps) => {
  const { t } = useTranslation();
  const { field } = useController({ ...props, name, control });

  return (
    <Controller
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <Autocomplete
          freeSolo
          forcePopupIcon
          disableClearable
          id={name}
          options={options}
          value={value}
          onChange={(_, value) => {
            if (value === null) {
              value = "";
            }
            onChange(value);
          }}
          PaperComponent={CustomPaper}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: error ? "1px solid #BD002A" : "1px solid #D5D5D5",
              },
              "&:hover fieldset": {
                border: error ? "1px solid #BD002A" : "1px solid #D5D5D5",
              },
              "&.Mui-focused fieldset": {
                border: error ? "1px solid #BD002A" : "1px solid #000000",
              },

              ...TextFieldStyles,
            },
          }}
          renderInput={(params) => (
            <>
              <Box height={4} />
              <InputLabel text={labelText} />
              <Box height={4} />
              <TextField
                {...field}
                {...params}
                placeholder={placeholder}
                onChange={(e) => {
                  const value = e.target.value;
                  onChange(value);
                }}
              />
              <Box height={4} />
              {error?.message && (
                <>
                  <Box height={4} />
                  <InputHelperText text={t(`${error.message}` as any)} />
                </>
              )}
            </>
          )}
        />
      )}
      name={name}
      control={control}
    />
  );
};

const CustomPaper = ({ children }: any) => {
  return (
    <Paper
      sx={{
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option[aria-selected='true']": {
            bgcolor: "grey.100",
            "&.Mui-focused": {
              bgcolor: "grey.100",
            },
          },
        },
      }}
    >
      {children}
    </Paper>
  );
};

export default SearchInput;
