import { Box, BoxProps } from "@mui/material";
import { ReactNode } from "react";

interface InputWrapperProps {
  children: ReactNode;
}

const InputWrapper = ({
  children,
  maxWidth = { sm: "335px" },
  ...props
}: InputWrapperProps & BoxProps) => {
  return (
    <Box {...props} sx={{ maxWidth }}>
      {children}
    </Box>
  );
};

export default InputWrapper;
