import { Control } from "react-hook-form";
import { AddressFormValues } from "src/pages/Address";
import TextInput from "src/components/Inputs/TextInput";
import { Box, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import InputWrapper from "src/components/Inputs/InputWrapper";

interface AddressInputsDeliveryAddressFormProps {
  control: Control<AddressFormValues>;
}

const AddressInputsDeliveryAddressForm = ({
  control,
}: AddressInputsDeliveryAddressFormProps) => {
  const { t } = useTranslation();
  const streetLabel = t("page.address.formFields.street.label");
  const houseNumberLabel = t("page.address.formFields.houseNumber.label");
  const zipCodeLabel = t("page.address.formFields.zipCode.label");
  const cityLabel = t("page.address.formFields.city.label");

  return (
    <>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <TextInput
              fullWidth
              control={control}
              name="streetDeliveryAddress"
              labelText={streetLabel}
            />
          </Grid>

          <Grid item sm={6}>
            <TextInput
              fullWidth
              control={control}
              name="houseNumberDeliveryAddress"
              labelText={houseNumberLabel}
            />
          </Grid>
        </Grid>

        <Box height={20} />

        <Stack direction={"row"} gap={3}>
          <InputWrapper maxWidth={"120px"}>
            <TextInput
              control={control}
              name="zipCodeDeliveryAddress"
              labelText={zipCodeLabel}
            />
          </InputWrapper>

          <InputWrapper width={"265px"}>
            <TextInput
              control={control}
              name="cityDeliveryAddress"
              labelText={cityLabel}
            />
          </InputWrapper>
        </Stack>
      </Box>

      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <Stack direction={"row"} gap={3}>
          <InputWrapper width={"100%"}>
            <TextInput
              fullWidth
              control={control}
              name="streetDeliveryAddress"
              labelText={streetLabel}
            />
          </InputWrapper>

          <InputWrapper maxWidth={"120px"}>
            <TextInput
              control={control}
              name="houseNumberDeliveryAddress"
              labelText={houseNumberLabel}
            />
          </InputWrapper>
        </Stack>

        <Box height={20} />

        <Stack direction={"row"} gap={3}>
          <InputWrapper maxWidth={"120px"}>
            <TextInput
              control={control}
              name="zipCodeDeliveryAddress"
              labelText={zipCodeLabel}
            />
          </InputWrapper>

          <InputWrapper width={"100%"}>
            <TextInput
              fullWidth
              control={control}
              name="cityDeliveryAddress"
              labelText={cityLabel}
            />
          </InputWrapper>
        </Stack>
      </Box>
    </>
  );
};

export default AddressInputsDeliveryAddressForm;
