import { CircularProgress, useTheme } from "@mui/material";
import React, { Suspense, useCallback, useEffect, useMemo } from "react";
import { Application } from "@splinetool/runtime";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import {
  SESSION_KEYS,
  SITE_TECHNICAL_DATA,
  USER_PROFILE_DATA,
} from "src/consts/session";
import { WallboxTypes } from "src/pages/Wallbox";
import { PvSetupTypes } from "src/pages/PvSetup";
import { BatterySetupTypes } from "src/pages/BatterySetup";
const Spline = React.lazy(() => import("@splinetool/react-spline"));

interface Props {
  animations: Animations;
}

export interface Animations {
  Sun?: boolean;
  Car?: boolean;
  Panels?: boolean;
  Battery?: boolean;
  People?: boolean;
  continue?: boolean;
}

const changeAnimations = (
  app: Application,
  animations: Animations,
  defaultAnimations: Animations
) => {
  app.setVariables({ ...defaultAnimations, ...animations });
};

const SplineScene = ({ animations }: Props) => {
  const theme = useTheme();
  const [app, setApp] = React.useState<Application | null>(null);
  const [getSessionStorageItem] = useSessionStorage<SITE_TECHNICAL_DATA>(
    SESSION_KEYS.SITE_TECHNICAL_DATA
  );
  const [getUserSessionStorageItem] = useSessionStorage<USER_PROFILE_DATA>(
    SESSION_KEYS.USER_PROFILE_DATA
  );

  const defaultAnimations = useMemo(() => {
    const sessionData = getSessionStorageItem();
    const userSessionData = getUserSessionStorageItem();
    return {
      Sun: sessionData?.pvSetup?.pvInstalled === PvSetupTypes.JA,
      Car: sessionData?.wallboxInstalled === WallboxTypes.JA,
      Panels: sessionData?.pvSetup?.pvInstalled === PvSetupTypes.JA,
      Battery:
        sessionData?.batterySetup?.batteryInstalled === BatterySetupTypes.JA,
      People: !!userSessionData?.yearlyConsumptionKwh,
      continue: false,
    };
  }, [getSessionStorageItem, getUserSessionStorageItem]);

  useEffect(() => {
    if (app) {
      changeAnimations(app, animations, defaultAnimations);
    }
  }, [animations, app, defaultAnimations]);

  const onLoad = useCallback(
    (splineApp: Application) => {
      splineApp.setZoom(4);
      splineApp.setBackgroundColor(theme.palette.app.primary);
      setApp(splineApp);
    },
    [theme.palette.app.primary]
  );

  return (
    <Suspense fallback={<CircularProgress />}>
      <Spline
        onLoad={onLoad}
        scene="https://prod.spline.design/A3wqZPUTYwU43bey/scene.splinecode"
      />
    </Suspense>
  );
};

export default SplineScene;
