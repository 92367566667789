import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InfoMaloID } from "src/assets";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import ProgressBarTitle from "src/components/Displays/ProgressBarTitle";

const MarketLocationModalContent = () => {
  const { t } = useTranslation();
  return (
    <Stack gap={4}>
      <Stack gap={4}>
        <ProgressBarTitle
          color="#222"
          text={t("page.technicalDetails.marketLocationId.infoSubtitle")}
        />
        <img
          style={{
            maxWidth: "347px",
            maxHeight: "132.139px",
          }}
          width="100%"
          height="100%"
          src={InfoMaloID}
          alt="House"
        />
        <PageSubTitle
          color="#222"
          text={t("page.technicalDetails.marketLocationId.imageAlt")}
        />
      </Stack>
      <ProgressBarTitle
        color="#222"
        text={t("page.technicalDetails.marketLocationId.infoText")}
      />
    </Stack>
  );
};

export default MarketLocationModalContent;
