import { IconButton, SvgIcon, IconButtonProps, styled } from "@mui/material";

interface BackButtonProps {
  variant?: "dark" | "light";
  onClick: () => void;
}

const BackButton = ({
  variant = "dark",
  onClick,
  ...props
}: BackButtonProps & IconButtonProps) => {
  const BackButtonStyles = styled(IconButton)(({ theme }) => ({
    padding: 0,
    borderRadius: 2,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }));

  return (
    <BackButtonStyles {...props} onClick={onClick} aria-label="back-button">
      <SvgIcon sx={{ margin: 3 }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.75 12.75C20.75 13.1642 20.4142 13.5 20 13.5L6.81066 13.5L12.2803 18.9697C12.5732 19.2626 12.5732 19.7374 12.2803 20.0303C11.9874 20.3232 11.5126 20.3232 11.2197 20.0303L4.46967 13.2803C4.17678 12.9874 4.17678 12.5126 4.46967 12.2197L11.2197 5.46967C11.5126 5.17678 11.9874 5.17678 12.2803 5.46967C12.5732 5.76256 12.5732 6.23744 12.2803 6.53033L6.81066 12L20 12C20.4142 12 20.75 12.3358 20.75 12.75Z"
            fill={variant === "dark" ? "black" : "white"}
          />
        </svg>
      </SvgIcon>
    </BackButtonStyles>
  );
};

export default BackButton;
