import { Box } from "@mui/material";
import FormContainer from "src/components/Displays/FormContainer";
import Page from "src/components/Layout/Page";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import TechnicalDetailsInputsForm from "src/components/Forms/TechnicalDetailsInputsForm/TechnicalDetailsInputsForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { technicalDetailsInputsFormSchema } from "src/schema/technicalDetailsInputsFormSchema";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { SESSION_KEYS, SITE_TECHNICAL_DATA } from "src/consts/session";
import { ROUTES } from "src/consts/routes";

export interface TechnicalDetailsFormValues {
  meterNumber: string;
  marketLocationId?: string | null | undefined;
}

const TechnicalDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageTitle = t("page.technicalDetails.pageTitle");
  const pageSubTitle = t("page.technicalDetails.pageSubTitle");
  const formTitle = t("page.technicalDetails.formTitle");
  const formSubTitle = t("page.technicalDetails.formSubTitle");
  const [getSessionStorageItem, setSessionStorageItem] =
    useSessionStorage<SITE_TECHNICAL_DATA>(SESSION_KEYS.SITE_TECHNICAL_DATA);

  const { control, handleSubmit } = useForm<TechnicalDetailsFormValues>({
    defaultValues: {
      meterNumber: getSessionStorageItem()?.meterNumber ?? "",
      marketLocationId: getSessionStorageItem()?.marketLocationId ?? "",
    },
    mode: "onSubmit",
    resolver: yupResolver(technicalDetailsInputsFormSchema),
  });

  const onSubmit = (data: TechnicalDetailsFormValues) => {
    const sessionData = getSessionStorageItem();
    const updatedSessionData: SITE_TECHNICAL_DATA = {
      ...sessionData,
      meterNumber: data.meterNumber,
      marketLocationId: data.marketLocationId ?? "",
    };
    setSessionStorageItem(updatedSessionData);
    navigate(ROUTES.CONTRACT_CHANGE);
  };

  const handleBackClick = () => {
    navigate(ROUTES.ADDRESS);
  };

  return (
    <Page pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <FormContainer
        title={formTitle}
        subTitle={formSubTitle}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ marginTop: 6, marginBottom: 3 }}>
          <TechnicalDetailsInputsForm control={control} />
        </Box>
        <FormSubmitContainer onClick={handleBackClick} />
      </FormContainer>
    </Page>
  );
};

export default TechnicalDetails;
