import { Control } from "react-hook-form";
import { YourNameFormValues } from "src/pages/YourName";
import TextInput from "src/components/Inputs/TextInput";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import InputWrapper from "src/components/Inputs/InputWrapper";

interface YourNameInputsFormProps {
  control: Control<YourNameFormValues>;
}

const YourNameInputsForm = ({ control }: YourNameInputsFormProps) => {
  const { t } = useTranslation();
  const firstNameLabel = t("page.yourName.formFields.firstName.label");
  const lastNameLabel = t("page.yourName.formFields.lastName.label");

  return (
    <Stack sx={{ gap: 5 }}>
      <InputWrapper>
        <TextInput
          fullWidth
          control={control}
          name="firstName"
          labelText={firstNameLabel}
        />
      </InputWrapper>

      <InputWrapper>
        <TextInput
          fullWidth
          control={control}
          name="lastName"
          labelText={lastNameLabel}
        />
      </InputWrapper>
    </Stack>
  );
};

export default YourNameInputsForm;
