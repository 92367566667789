import { SvgIcon, Typography, styled } from "@mui/material";

interface InputHelperTextProps {
  text: string;
}

const InputHelperText = ({ text }: InputHelperTextProps) => {
  const InputHelperTextStyles = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "18px",
  }));

  return (
    <InputHelperTextStyles
      sx={{ display: "flex", alignItems: "center", gap: 2 }}
    >
      <SvgIcon>
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 18.25C14.4183 18.25 18 14.6683 18 10.25C18 5.83172 14.4183 2.25 10 2.25C5.58172 2.25 2 5.83172 2 10.25C2 14.6683 5.58172 18.25 10 18.25ZM10 7.62695C10.7603 7.62695 11.3767 7.01057 11.3767 6.25022C11.3767 5.48987 10.7603 4.87349 10 4.87349C9.23965 4.87349 8.62327 5.48987 8.62327 6.25022C8.62327 7.01057 9.23965 7.62695 10 7.62695ZM9.0056 9.49854V16.0977H11.0056V9.49854H9.0056Z"
            fill="#BD002A"
          />
        </svg>
      </SvgIcon>
      {text}
    </InputHelperTextStyles>
  );
};

export default InputHelperText;
