import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { Box, RadioGroup, RadioGroupProps } from "@mui/material";

import { ReactNode } from "react";
import Text from "../Displays/Text";
import { useTranslation } from "react-i18next";

interface RadioGroupInputProps<T extends FieldValues>
  extends UseControllerProps<T> {
  children: ReactNode;
}

const RadioGroupInput = <T extends FieldValues>({
  name,
  control,
  children,
  ...props
}: RadioGroupInputProps<T> & RadioGroupProps) => {
  const { t } = useTranslation();
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <RadioGroup {...props} {...field} aria-labelledby="radio-buttons-group">
          {children}
          {error?.message && (
            <Box
              sx={{
                mt: 2,
              }}
            >
              <Text color="red" text={t(error.message as any)} />
            </Box>
          )}
        </RadioGroup>
      )}
      name={name}
      control={control}
    />
  );
};

export default RadioGroupInput;
