import * as React from "react";
import { default as MUIAccordion } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ChevronDown, ChevronUp } from "src/assets";
import Text from "../Displays/Text";
import { Stack, useTheme } from "@mui/material";
import FormHeader from "../Displays/FormHeader";
import PageSubTitle from "../Displays/PageSubTitle";

interface ControlledAccordionProps {
  title: string;
  subtitleCollapsed: string;
  subtitleUnCollapsed: string;
  value: string;
  name: string;
  children?: React.ReactNode;
  expanded: string | boolean;
  backgroundColor?: string;
  handleChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

export default function ControlledAccordion({
  children,
  subtitleCollapsed,
  subtitleUnCollapsed,
  value,
  title,
  expanded,
  handleChange,
  backgroundColor,
  name,
}: ControlledAccordionProps) {
  const theme = useTheme();
  return (
    <MUIAccordion
      disableGutters
      sx={(theme) => ({
        boxShadow: "none",
        background: backgroundColor ? theme.palette.form.background : undefined,
      })}
      expanded={expanded === name}
      onChange={handleChange(name)}
    >
      <AccordionSummary
        sx={{
          padding: 0,
        }}
      >
        <Stack width="100%">
          <Stack direction="row" justifyContent="space-between">
            <Text text={title} fontWeight={700} />
            <Stack>{expanded === name ? <ChevronDown /> : <ChevronUp />}</Stack>
          </Stack>

          {expanded === name && (
            <Text
              fontWeight={700}
              sx={{
                fontSize: "11px",
                lineHeight: "18px",
              }}
              text={subtitleUnCollapsed}
            />
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <PageSubTitle
              color={theme.palette.app.secondaryFont}
              text={subtitleCollapsed}
            />
            <FormHeader text={value} />
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
        }}
      >
        {children}
      </AccordionDetails>
    </MUIAccordion>
  );
}
