import { Stack } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { TARIFF_PATHS, ORDER_PATHS, ERROR_PATHS } from "src/consts/paths";
import HorizontalLinearProgressBar from "../ProgressBar/HorizontalLinearProgressBar";
import SplineScene, { Animations } from "../Spline/Spline";
import { tariffCalculator } from "../TariffCalculatorProgressBar/TariffCalculatorProgressBar";
import SplinePage from "../Layout/SplinePage";
import { useTranslation } from "react-i18next";
import { ROUTES } from "src/consts/routes";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { Tariff } from "src/models/clientsModel";
import { SESSION_KEYS } from "src/consts/session";
import CompanyDetails from "./CompanyDetails";

export interface GlobalContextProps {
  setAnimations: Dispatch<SetStateAction<Animations>>;
}
const GlobalContext = () => {
  const { t } = useTranslation();
  const [animations, setAnimations] = useState<Animations>({});
  const { pathname } = useLocation();
  const [getSessionStorageItem] = useSessionStorage<Tariff[]>(
    SESSION_KEYS.TARIFFS
  );

  const tariffDisplayName =
    getSessionStorageItem()?.[0]?.tariffDisplayName || "StromWallet";
  const tariffProviderDisplayName =
    getSessionStorageItem()?.[0]?.tariffProviderDisplayName || "";

  return (
    <>
      {TARIFF_PATHS.includes(pathname) && (
        <SplinePage
          pageTitle={
            pathname === ROUTES.CALCULATED_SAVINGS || pathname === ROUTES.TARIF
              ? ""
              : t("globalContext.pageTitle")
          }
          pageSubTitle={
            pathname === ROUTES.CALCULATED_SAVINGS || pathname === ROUTES.TARIF
              ? ""
              : t("globalContext.pageSubtitle")
          }
        >
          {pathname === ROUTES.TARIF && (
            <CompanyDetails
              by={t("globalContext.by")}
              tariffDisplayName={tariffDisplayName}
              tariffProviderDisplayName={tariffProviderDisplayName}
            />
          )}
          {pathname === ROUTES.TARIF ? (
            <></>
          ) : (
            <Stack>
              <SplineScene animations={animations} />
            </Stack>
          )}

          {pathname !== ROUTES.CALCULATED_SAVINGS &&
            pathname !== ROUTES.TARIF && (
              <Stack
                sx={{
                  display: { xs: "default", sm: "none" },
                  px: 4,
                  py: 4,
                }}
              >
                <HorizontalLinearProgressBar
                  variant="determinate"
                  value={tariffCalculator(pathname)}
                />
              </Stack>
            )}
          <Outlet context={{ setAnimations }} />
        </SplinePage>
      )}
      {ORDER_PATHS.includes(pathname) && <Outlet />}
      {ERROR_PATHS.includes(pathname) && <Outlet />}
    </>
  );
};

export default GlobalContext;
