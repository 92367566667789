import { Box, Stack } from "@mui/material";
import PageTitle from "../Displays/PageTitle";
import TariffProviderIcon from "../TarrifProviderIcon/TariffProbiderIcon";

interface CompanyDetailsProps {
  tariffDisplayName: string;
  tariffProviderDisplayName: string;
  by: string;
}

const CompanyDetails = ({
  tariffDisplayName,
  tariffProviderDisplayName,
  by,
}: CompanyDetailsProps) => {
  return (
    <Stack>
      <PageTitle text={tariffDisplayName} />
      <Stack direction="row">
        <PageTitle text={by} />
        <Box>
          <TariffProviderIcon
            tariffProviderDisplayName={tariffProviderDisplayName}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default CompanyDetails;
