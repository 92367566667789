import { useCallback, useState } from "react";

export const useToggle = (
  initialState = false
): [boolean, () => void, () => void, () => void] => {
  const [state, setState] = useState<boolean>(initialState);
  const toggle = useCallback((): void => setState((state) => !state), []);

  const on = useCallback((): void => setState(true), []);
  const off = useCallback((): void => setState(false), []);

  return [state, toggle, on, off];
};
