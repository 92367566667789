import Page from "src/components/Layout/Page";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import FormContainer from "src/components/Displays/FormContainer";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addressInputsFormSchema } from "src/schema/addressInputsFormSchema";
import AddressInputsForm from "src/components/Forms/AddressInputsForm";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { ROUTES } from "src/consts/routes";
import { SESSION_KEYS, USER_PROFILE_DATA } from "src/consts/session";
import { useEffect, useState } from "react";
import AddressInputsDeliveryAddressForm from "src/components/Forms/AddressInputsDeliveryAddressForm";
import CheckboxInput from "src/components/Inputs/CheckboxInput";
import Text from "src/components/Displays/Text";

export interface AddressFormValues {
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;

  useDeliveryAddressChecked: boolean;

  streetDeliveryAddress: string;
  houseNumberDeliveryAddress: string;
  zipCodeDeliveryAddress: string;
  cityDeliveryAddress: string;
}

const Address = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formTitle = t("page.address.formTitle");
  const formSubTitle = t("page.address.formSubTitle");
  const pageTitle = t("page.address.pageTitle");
  const pageSubTitle = t("page.address.pageSubTitle");
  const deliveryAddressInputLabelText = t(
    "page.address.formFields.deliveryAddressCheck.inputLabelText"
  );
  const deliveryAddressCheckboxLabelText = t(
    "page.address.formFields.deliveryAddressCheck.checkboxLabelText"
  );

  const [name, setName] = useState({
    firstName: "",
    lastName: "",
  });

  const [showDeliveryAddressForm, setShowDeliveryAddressForm] =
    useState<Boolean>();

  const [getSessionStorageItem, setSessionStorageItem] =
    useSessionStorage<USER_PROFILE_DATA>(SESSION_KEYS.USER_PROFILE_DATA);

  const { watch, reset, control, trigger, getValues, handleSubmit } =
    useForm<AddressFormValues>({
      defaultValues: {
        street: "",
        houseNumber: "",
        zipCode: "",
        city: "",

        useDeliveryAddressChecked: false,
      },
      mode: "onSubmit",
      resolver: yupResolver(addressInputsFormSchema),
    });

  useEffect(() => {
    const localData = getSessionStorageItem();

    setName({
      firstName: localData?.firstName || "",
      lastName: localData?.lastName || "",
    });

    reset({
      ...localData,
      street: localData?.street,
      houseNumber: localData?.houseNumber,
      zipCode: localData?.zipCode,
      city: localData?.city,

      useDeliveryAddressChecked:
        localData?.useDeliveryAddressChecked === "true" ? true : false,

      streetDeliveryAddress: localData?.streetDeliveryAddress,
      houseNumberDeliveryAddress: localData?.streetNumberDeliveryAddress,
      zipCodeDeliveryAddress: localData?.zipCodeDeliveryAddress,
      cityDeliveryAddress: localData?.cityDeliveryAddress,
    });

    if (localData?.useDeliveryAddressChecked === "true") {
      setShowDeliveryAddressForm(false);
    } else {
      setShowDeliveryAddressForm(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "useDeliveryAddressChecked") {
        const formRequiredFieldsEmpty =
          getValues().street.length === 0 ||
          getValues().houseNumber.length === 0 ||
          getValues().zipCode.length === 0 ||
          getValues().city.length === 0;

        trigger();

        if (formRequiredFieldsEmpty) {
          reset({
            ...getValues(),
            useDeliveryAddressChecked: false,
          });
          return;
        }

        reset({
          ...getValues(),

          streetDeliveryAddress: getValues().street,
          houseNumberDeliveryAddress: getValues().houseNumber,
          zipCodeDeliveryAddress: getValues().zipCode,
          cityDeliveryAddress: getValues().city,
        });

        if (value.useDeliveryAddressChecked === true) {
          setShowDeliveryAddressForm(false);
        } else {
          setShowDeliveryAddressForm(true);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, reset, trigger, getValues]);

  const onSubmit = (data: AddressFormValues) => {
    const sessionData = getSessionStorageItem();
    const updatedSessionData: USER_PROFILE_DATA = {
      ...sessionData,
      street: data.street,
      houseNumber: data.houseNumber,
      zipCode: data.zipCode,
      city: data.city,

      useDeliveryAddressChecked: data.useDeliveryAddressChecked?.toString(),

      streetDeliveryAddress: data.useDeliveryAddressChecked
        ? data.street
        : data.streetDeliveryAddress,
      streetNumberDeliveryAddress: data.useDeliveryAddressChecked
        ? data.houseNumber
        : data.houseNumberDeliveryAddress,
      zipCodeDeliveryAddress: data.useDeliveryAddressChecked
        ? data.zipCode
        : data.zipCodeDeliveryAddress,
      cityDeliveryAddress: data.useDeliveryAddressChecked
        ? data.city
        : data.cityDeliveryAddress,
    };
    setSessionStorageItem(updatedSessionData);
    navigate(ROUTES.TECHNICAL_DETAILS);
  };

  const handleBackClick = () => {
    navigate(ROUTES.CONTACT_INFORMATION);
  };

  return (
    <Page pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <FormContainer
        title={formTitle}
        subTitle={formSubTitle}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 6,
            marginBottom: 3,
            gap: 5,
          }}
        >
          <AddressInputsForm control={control} />
          <CheckboxInput
            control={control}
            name="useDeliveryAddressChecked"
            inputLabelText={deliveryAddressInputLabelText}
            checkboxLabelText={deliveryAddressCheckboxLabelText}
          />
          {showDeliveryAddressForm && (
            <AddressInputsDeliveryAddressForm control={control} />
          )}
          {!showDeliveryAddressForm && (
            <Box>
              <Text text={`${name.firstName} ${name.lastName}`} />
              <Text text={`${getValues().street} ${getValues().houseNumber}`} />
              <Text text={`${getValues().zipCode} ${getValues().city}`} />
            </Box>
          )}
        </Box>
        <FormSubmitContainer onClick={handleBackClick} />
      </FormContainer>
    </Page>
  );
};

export default Address;
