import * as yup from "yup";
import { AddressFormValues } from "src/pages/Address";
import { REGEX } from "src/consts/regex";

export const addressInputsFormSchema: yup.ObjectSchema<AddressFormValues> =
  yup.object({
    street: yup
      .string()
      .required("page.address.formErrors.street")
      .min(2, "page.address.formErrors.street")
      .max(30, "page.address.formErrors.street")
      .matches(REGEX.STREET, "page.address.formErrors.street"),

    houseNumber: yup
      .string()
      .required("page.address.formErrors.houseNumber")
      .min(1, "page.address.formErrors.houseNumber")
      .max(30, "page.address.formErrors.houseNumber")
      .matches(REGEX.HOUSENUMBER, "page.address.formErrors.houseNumber"),

    zipCode: yup
      .string()
      .required("page.address.formErrors.zipCode")
      .min(5, "page.address.formErrors.zipCode")
      .max(5, "page.address.formErrors.zipCode")
      .matches(REGEX.ZIP_CODE, "page.address.formErrors.zipCode"),

    city: yup
      .string()
      .required("page.address.formErrors.city")
      .min(1, "page.address.formErrors.city")
      .max(50, "page.address.formErrors.city")
      .matches(REGEX.CITY, "page.address.formErrors.city"),

    cityDeliveryAddress: yup
      .string()
      .required("page.address.formErrors.city")
      .min(1, "page.address.formErrors.city")
      .max(50, "page.address.formErrors.city")
      .matches(REGEX.CITY, "page.address.formErrors.city"),

    useDeliveryAddressChecked: yup.boolean().required(),

    streetDeliveryAddress: yup
      .string()
      .required("page.address.formErrors.street")
      .min(2, "page.address.formErrors.street")
      .max(30, "page.address.formErrors.street")
      .matches(REGEX.STREET, "page.address.formErrors.street"),

    houseNumberDeliveryAddress: yup
      .string()
      .required("page.address.formErrors.houseNumber")
      .min(1, "page.address.formErrors.houseNumber")
      .max(30, "page.address.formErrors.houseNumber")
      .matches(REGEX.HOUSENUMBER, "page.address.formErrors.houseNumber"),

    zipCodeDeliveryAddress: yup
      .string()
      .required("page.address.formErrors.zipCode")
      .min(5, "page.address.formErrors.zipCode")
      .max(5, "page.address.formErrors.zipCode")
      .matches(REGEX.ZIP_CODE, "page.address.formErrors.zipCode"),
  });
