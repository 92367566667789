import { Typography, styled } from "@mui/material";

interface PageTitleProps {
  text: string;
  color?: string;
  opacity?: number;
}

const PageTitle = ({ text, color, opacity }: PageTitleProps) => {
  const PageTitleStyles = styled(Typography)(({ theme }) => ({
    color: color || theme.palette.app.primaryFont,
    fontFamily: "isonType",
    opacity: opacity || 1,
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "35px",
  }));

  return <PageTitleStyles variant="h1">{text}</PageTitleStyles>;
};

export default PageTitle;
