const anonymizeIBAN = (iban: string): string => {
  if (iban.length === 0) return "";

  const countryCodeDE = "DE";
  const firstTwoNumbers = iban.substring(0, 2);
  const lastFourNumbers = iban.substring(iban.length - 4);
  const middleNumbers = iban.substring(2, iban.length - 4);
  const hiddenNumbers = "X".repeat(middleNumbers.length);

  const formattedIban = (
    countryCodeDE +
    firstTwoNumbers +
    hiddenNumbers +
    lastFourNumbers
  ).replace(/(.{4})/g, "$1 ");

  return formattedIban;
};

export default anonymizeIBAN;
